
import React from 'react';

export default function (props) {

  return (<>

    <div className="hidden">

      <div className="tw:-rotate-90"></div>
      <div className="tw:align-middle"></div>
      <div className="tw:basis-0"></div>
      <div className="tw:bg-[#14b99b]"></div>
      <div className="tw:bg-[#76003b]"></div>
      <div className="tw:bg-[#939aa8]"></div>
      <div className="tw:bg-[#96004b]"></div>
      <div className="tw:bg-[#9a1638]"></div>
      <div className="tw:bg-[#d9d9d9]"></div>
      <div className="tw:bg-[#edeff6]"></div>
      <div className="tw:bg-slate-300"></div>
      <div className="tw:bg-white"></div>
      <div className="tw:block"></div>
      <div className="tw:border-0"></div>
      <div className="tw:border-[#979797]"></div>
      <div className="tw:border-b-0"></div>
      <div className="tw:border-b-[#edeff6]"></div>
      <div className="tw:border-collapse"></div>
      <div className="tw:border-r"></div>
      <div className="tw:border-solid"></div>
      <div className="tw:border-t"></div>
      <div className="tw:border-transparent"></div>
      <div className="tw:bottom-0"></div>
      <div className="tw:box-border"></div>
      <div className="tw:cursor-pointer"></div>
      <div className="tw:disabled:cursor-not-allowed:disabled"></div>
      <div className="tw:fixed"></div>
      <div className="tw:flex"></div>
      <div className="tw:flex-1"></div>
      <div className="tw:flex-col"></div>
      <div className="tw:flex-none"></div>
      <div className="tw:flex-row"></div>
      <div className="tw:font-['Nunito_Sans']"></div>
      <div className="tw:font-['Poppins']"></div>
      <div className="tw:font-bold"></div>
      <div className="tw:font-normal"></div>
      <div className="tw:gap-0"></div>
      <div className="tw:gap-10"></div>
      <div className="tw:gap-16"></div>
      <div className="tw:gap-2"></div>
      <div className="tw:gap-2.5"></div>
      <div className="tw:gap-32"></div>
      <div className="tw:gap-4"></div>
      <div className="tw:gap-8"></div>
      <div className="tw:grow"></div>
      <div className="tw:h-10"></div>
      <div className="tw:h-12"></div>
      <div className="tw:h-48"></div>
      <div className="tw:h-64"></div>
      <div className="tw:h-auto"></div>
      <div className="tw:h-full"></div>
      <div className="tw:h-px"></div>
      <div className="tw:hidden"></div>
      <div className="tw:indent-0"></div>
      <div className="tw:inline-flex"></div>
      <div className="tw:items-center"></div>
      <div className="tw:items-start"></div>
      <div className="tw:items-stretch"></div>
      <div className="tw:justify-between"></div>
      <div className="tw:justify-center"></div>
      <div className="tw:justify-start"></div>
      <div className="tw:leading-10"></div>
      <div className="tw:leading-5"></div>
      <div className="tw:leading-9"></div>
      <div className="tw:leading-[48px]"></div>
      <div className="tw:leading-[64px]"></div>
      <div className="tw:leading-loose"></div>
      <div className="tw:leading-none"></div>
      <div className="tw:leading-tight"></div>
      <div className="tw:left-0"></div>
      <div className="tw:m-0"></div>
      <div className="tw:max-w-xl"></div>
      <div className="tw:min-h-12"></div>
      <div className="tw:mt-4"></div>
      <div className="tw:mx-auto"></div>
      <div className="tw:my-8"></div>
      <div className="tw:opacity-80"></div>
      <div className="tw:origin-top-left"></div>
      <div className="tw:overflow-hidden"></div>
      <div className="tw:p-0"></div>
      <div className="tw:p-1.5"></div>
      <div className="tw:p-16"></div>
      <div className="tw:p-2"></div>
      <div className="tw:p-4"></div>
      <div className="tw:p-8"></div>
      <div className="tw:pb-4"></div>
      <div className="tw:pl-1.5"></div>
      <div className="tw:pl-32"></div>
      <div className="tw:pl-4"></div>
      <div className="tw:place-content-stretch"></div>
      <div className="tw:place-items-center"></div>
      <div className="tw:pr-2"></div>
      <div className="tw:pt-8"></div>
      <div className="tw:px-0"></div>
      <div className="tw:px-10"></div>
      <div className="tw:px-4"></div>
      <div className="tw:py-2"></div>
      <div className="tw:py-2.5"></div>
      <div className="tw:py-4"></div>
      <div className="tw:py-6"></div>
      <div className="tw:relative"></div>
      <div className="tw:right-0"></div>
      <div className="tw:rounded"></div>
      <div className="tw:rounded-lg"></div>
      <div className="tw:rounded-md"></div>
      <div className="tw:rounded-none"></div>
      <div className="tw:rounded-xl"></div>
      <div className="tw:self-center"></div>
      <div className="tw:self-end"></div>
      <div className="tw:self-stretch"></div>
      <div className="tw:shrink"></div>
      <div className="tw:sm:flex-row"></div>
      <div className="tw:table"></div>
      <div className="tw:text-2xl"></div>
      <div className="tw:text-4xl"></div>
      <div className="tw:text-[#1f1f1f]"></div>
      <div className="tw:text-[#595d64]"></div>
      <div className="tw:text-[#76003b]"></div>
      <div className="tw:text-[#96004b]"></div>
      <div className="tw:text-[#979797]"></div>
      <div className="tw:text-[#9a1638]"></div>
      <div className="tw:text-base"></div>
      <div className="tw:text-black"></div>
      <div className="tw:text-center"></div>
      <div className="tw:text-left"></div>
      <div className="tw:text-lg"></div>
      <div className="tw:text-right"></div>
      <div className="tw:text-white"></div>
      <div className="tw:text-xl"></div>
      <div className="tw:text-xs"></div>
      <div className="tw:top-0"></div>
      <div className="tw:tracking-tight"></div>
      <div className="tw:tracking-tighter"></div>
      <div className="tw:tracking-wider"></div>
      <div className="tw:tracking-widest"></div>
      <div className="tw:translate-y-[256px]"></div>
      <div className="tw:underline"></div>
      <div className="tw:uppercase"></div>
      <div className="tw:w-16"></div>
      <div className="tw:w-48"></div>
      <div className="tw:w-56"></div>
      <div className="tw:w-60"></div>
      <div className="tw:w-64"></div>
      <div className="tw:w-80"></div>
      <div className="tw:w-full"></div>
      <div className="tw:xl:block"></div>
      <div className="tw:xl:h-12"></div>
      <div className="tw:xl:hidden"></div>
      <div className="tw:xl:leading-none"></div>
      <div className="tw:xl:pb-0"></div>
      <div className="tw:xl:pl-0"></div>
      <div className="tw:xl:rotate-0"></div>
      <div className="tw:xl:self-center"></div>
      <div className="tw:xl:text-center"></div>
      <div className="tw:xl:translate-y-[0]"></div>
      <div className="tw:xl:w-64"></div>
      <div className="tw:z-[999999]"></div>

    </div>

  </>);

};
