import React from 'react';

import { toPrettyDollarsRound } from './helpers';

{/* <PurchaseCartIcon isLoggedIn={true|false} cart={} */}
class PurchaseCartIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onCartChange = this.onCartChange.bind(this);
  }
  onCartChange(cart) {
    console.log(`[PurchaseCartIcon] Cart change happened.`, { items: cart.items, total: cart.total });
    // the cart changed, force re-render
    this.setState({});
  }
  handleClickOutside(event) {
    if (this.state.isOpen) {
      console.log('[PurchaseCartIcon] Handling clickOutside...');
      if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
        this.setState({ isOpen: false });
      }
    }
  }
  async componentDidMount() {
    try {
      document.addEventListener('mousedown', this.handleClickOutside);
      // subscribe to cart changes
      this.props.cart.subscribe(this.onCartChange);
    }
    catch(e) {
      console.error('[PurchaseCartIcon] Error in componentDidMount().', e);
    }
  }
  async componentWillUnmount() {
    try {
      document.removeEventListener('mousedown', this.handleClickOutside);
      // unsubscribe from cart changes
      this.props.cart.unsubscribe(this.onCartChange);
    }
    catch(e) {
      console.error('[PurchaseCartIcon] Error in componentWillUnmount().', e);
    }
  }
  render() {
    let cart = this.props.cart;

    if (!cart) {
      return '';
    }

    let cartData = cart.getCart();

    if (cartData.items.length === 0) {
      return '';
    }


    let cartItemsRender = [];
    for (let item of cartData.items) {
      cartItemsRender.push(
        <div className='purchaseCartIconMenuItem'>
          <div className='purchaseCartIconMenuItemLeft'>
            <CourseIcon svg={item.courseIcon} />
          </div>
          <div className='purchaseCartIconMenuItemRight'>
            <div className='cartItemText'> 
              {item.name}
            </div>
            <div className='cartItemPrice'>
              {toPrettyDollarsRound(item.stripeProduct.price.amount)}
            </div>
          </div>
        </div>
      );
    }
    if (cartData.items.length > 0) {
      cartItemsRender.push(
        <div>
          <div className='purchaseCartIconCheckout'>
            <div className='purchaseCartIconMenuItemLeft'>
              <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.34435 17.5144C8.30551 18.249 8.04377 18.8653 7.50003 19.355C7.25256 19.5778 6.963 19.7487 6.64842 19.8578C6.33384 19.9669 6.00061 20.012 5.66836 19.9903C5.33611 19.9686 5.01156 19.8806 4.71384 19.7315C4.41612 19.5824 4.15126 19.3753 3.93485 19.1222C3.71844 18.8692 3.55487 18.5754 3.45377 18.2581C3.35268 17.9409 3.31611 17.6066 3.34621 17.275C3.37631 16.9434 3.47249 16.6212 3.62906 16.3274C3.78563 16.0335 3.99943 15.774 4.25787 15.5641C4.31728 15.5247 4.36241 15.4672 4.38659 15.4001C4.41077 15.333 4.41271 15.26 4.39212 15.1917C3.99811 12.8704 3.61056 10.5486 3.2295 8.22614C2.92048 6.36865 2.6109 4.51116 2.30075 2.65368C2.27965 2.52365 2.21632 2.49832 2.10319 2.49917C1.68863 2.50423 0.429755 2.49917 0 2.49917V0.0126642C0.0616349 0.0126642 0.122429 0 0.18322 0C1.23861 0 3.13832 0 4.19371 0C4.33808 0 4.39887 0.0287068 4.42336 0.187438C4.54072 0.94732 4.67665 1.7072 4.80836 2.49748H20.8191C20.8258 2.58191 20.8343 2.64355 20.8343 2.70349C20.8343 3.42116 20.8343 4.13882 20.8343 4.85649C20.8321 4.96144 20.8135 5.0654 20.7794 5.16466C19.9942 7.52874 19.2078 9.89282 18.4204 12.2569C18.3621 12.4342 18.2912 12.5034 18.0894 12.5034C14.3181 12.495 10.5469 12.493 6.77561 12.4975H6.48179C6.56116 12.9822 6.6363 13.4448 6.71229 13.9075C6.76379 14.2199 6.82205 14.5306 6.8668 14.8439C6.88369 14.9579 6.93097 15.0009 7.04495 14.9958C7.1851 14.9891 7.32611 14.9958 7.46711 14.9958C10.6468 14.9958 13.827 14.9958 17.0078 14.9958C18.3064 14.9958 19.3154 15.83 19.5475 17.0821C19.7873 18.3765 18.9312 19.6379 17.6056 19.941C16.3493 20.2289 15.0203 19.4344 14.6826 18.1873C14.6243 17.9729 14.5982 17.7491 14.5577 17.5119L8.34435 17.5144Z" fill="currentColor" />
              </svg>
            </div>
            <div className='purchaseCartIconMenuItemCenter'>
              <a href='/purchase/return'>
                <div className='cartItemText'>
                  Check out
                </div>
              </a>
            </div>
            <div className='purchaseCartIconMenuItemRight'>
              <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path clip-rule="evenodd" d="M0.868654 0.468629C1.49349 -0.15621 2.50656 -0.15621 3.1314 0.468629L9.53139 6.86863C10.1562 7.49347 10.1562 8.50653 9.53139 9.13137L3.1314 15.5314C2.50656 16.1562 1.49349 16.1562 0.868654 15.5314C0.243815 14.9065 0.243815 13.8935 0.868654 13.2686L6.13728 8L0.868654 2.73137C0.243815 2.10653 0.243815 1.09347 0.868654 0.468629Z" fill="currentColor" />
              </svg>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div ref={this.wrapperRef} className='pi3purchaseCart'>
        <div className='purchaseCartIconSelectable' onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.34435 17.5144C8.30551 18.249 8.04377 18.8653 7.50003 19.355C7.25256 19.5778 6.963 19.7487 6.64842 19.8578C6.33384 19.9669 6.00061 20.012 5.66836 19.9903C5.33611 19.9686 5.01156 19.8806 4.71384 19.7315C4.41612 19.5824 4.15126 19.3753 3.93485 19.1222C3.71844 18.8692 3.55487 18.5754 3.45377 18.2581C3.35268 17.9409 3.31611 17.6066 3.34621 17.275C3.37631 16.9434 3.47249 16.6212 3.62906 16.3274C3.78563 16.0335 3.99943 15.774 4.25787 15.5641C4.31728 15.5247 4.36241 15.4672 4.38659 15.4001C4.41077 15.333 4.41271 15.26 4.39212 15.1917C3.99811 12.8704 3.61056 10.5486 3.2295 8.22614C2.92048 6.36865 2.6109 4.51116 2.30075 2.65368C2.27965 2.52365 2.21632 2.49832 2.10319 2.49917C1.68863 2.50423 0.429755 2.49917 0 2.49917V0.0126642C0.0616349 0.0126642 0.122429 0 0.18322 0C1.23861 0 3.13832 0 4.19371 0C4.33808 0 4.39887 0.0287068 4.42336 0.187438C4.54072 0.94732 4.67665 1.7072 4.80836 2.49748H20.8191C20.8258 2.58191 20.8343 2.64355 20.8343 2.70349C20.8343 3.42116 20.8343 4.13882 20.8343 4.85649C20.8321 4.96144 20.8135 5.0654 20.7794 5.16466C19.9942 7.52874 19.2078 9.89282 18.4204 12.2569C18.3621 12.4342 18.2912 12.5034 18.0894 12.5034C14.3181 12.495 10.5469 12.493 6.77561 12.4975H6.48179C6.56116 12.9822 6.6363 13.4448 6.71229 13.9075C6.76379 14.2199 6.82205 14.5306 6.8668 14.8439C6.88369 14.9579 6.93097 15.0009 7.04495 14.9958C7.1851 14.9891 7.32611 14.9958 7.46711 14.9958C10.6468 14.9958 13.827 14.9958 17.0078 14.9958C18.3064 14.9958 19.3154 15.83 19.5475 17.0821C19.7873 18.3765 18.9312 19.6379 17.6056 19.941C16.3493 20.2289 15.0203 19.4344 14.6826 18.1873C14.6243 17.9729 14.5982 17.7491 14.5577 17.5119L8.34435 17.5144Z" fill="white"/>
          </svg>
          {cartData && cartData.items && cartData.items.length > 0 &&
            <div className='purchaseCartIconSelectableCount'>
              {cartData.items.length}
            </div>
          }
        </div>

        {this.state.isOpen &&
          <div className='purchaseCartIconMenu'>
            {cartItemsRender}
          </div>
        }
      </div>
    );
  }
}

export default PurchaseCartIcon;

// <CourseIcon svg="" />
class CourseIcon extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div
        dangerouslySetInnerHTML={{__html: this.props.svg}}
      ></div>
    );
  }
}