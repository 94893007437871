'use strict';

import React from 'react';

import validation from './validation';
import enums from './enums';

import LoadingCenter from './LoadingCenter';
import { shallowCopy, getQueryAttribute, toPrettyDollars, toPrettyDollarsRound } from './helpers'; 


import {
  // getCompanyProducts2,
  submitCompanyOnboarding2
} from './ajax-vintage'; 

import {
  getProductsTeams,
} from './ajax';



import { emitPageView, emitTeamsWireInvoiceGenerated } from './analytics';


import NewPurchaseFlowCloud from './NewPurchaseFlowCloud';
import NewPurchaseFlowSelect from './NewPurchaseFlowSelect';
import CompanyOnboarding2ThankYouWire from './CompanyOnboarding2ThankYouWire';

const STRIPE_PUBKEY = __STRIPE_PUBLISHABLE_KEY__;


var stripe = null;
if (typeof Stripe !== 'undefined') {
  stripe = Stripe(STRIPE_PUBKEY);
} else {
  console.warn('[CompanyOnboarding] Stripe library not loaded.');
}

class CompanyOnboarding2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'loading', // loading, planSelect, planConfirm, invoice, wireInvoiceConfirm
      planForm: {
        licenseType: enums.CompanyOnboarding.LicenseType.PMFoundations,
        seats: 1,
        coachingPackages: 0,
        validate: false,
      },
      planFormTree: null,
      invoiceForm: {
        name: '', // Full Name e.g. John Smith
        email: '', // Email Address name@company.com
        companyName: '',
        companyAddress: '',
        companyVAT: '',
        seats: 1,
        coachingPackages: 0,
        paymentMethod: enums.CompanyOnboarding.PaymentMethod.Stripe,
        licenseType: enums.CompanyOnboarding.LicenseType.PMFoundations,
        message: '', // Message What would you like to discuss or what is your question?
        validate: false,
      },
      invoiceFormTree: null,
      isSubmitted: false,

      stripeCheckoutSessionId: null,
      standardProduct: { id: null, amount: 0 },
      premiumProduct: { id: null, amount: 0 },
      coachingProduct: { id: null, amount: 0 },
    };
    this.onLicenseTypeChanged = this.onLicenseTypeChanged.bind(this);
    this.onPlanFinalized = this.onPlanFinalized.bind(this);
    this.onPlanUnfinalized = this.onPlanUnfinalized.bind(this);
    this.mergeInvoiceForm = this.mergeInvoiceForm.bind(this);
    this.mergePlanForm = this.mergePlanForm.bind(this);
    this.validateInvoiceForm = this.validateInvoiceForm.bind(this);
    this.validatePlanForm = this.validatePlanForm.bind(this);
    this.submit = this.submit.bind(this);
    this.navigate = this.navigate.bind(this);
  }
  navigate(stage) {
    console.log(`[CompanyOnboarding2] Navigating to stage...`, { stage });
    this.setState({ stage });

    if (stage === 'planSelect') {
      setTimeout(() => {
        // console.log('scrolling to #planSelectPage...');
        document.getElementById('planSelectPage').scrollIntoView({ behavior: 'smooth' });
      }, 300);
      // INPROG google analytics
      emitPageView(`/checkout/teams/${stage}`, 'Checkout Teams - Select Plan');
    }

    else if (stage === 'planConfirm') {
      setTimeout(() => {
        // console.log('scrolling to #planConfirmPage...');
        document.getElementById('planConfirmPage').scrollIntoView({ behavior: 'smooth' });
      }, 300);
      // INPROG google analytics
      emitPageView(`/checkout/teams/${stage}`, 'Checkout Teams - Confirm Plan');
    }

    else if (stage === 'invoice') {
      setTimeout(() => {
        // console.log('scrolling to #companyOnboardingInvoiceForm...');
        document.getElementById('companyOnboardingInvoiceForm').scrollIntoView({ behavior: 'smooth' });
      }, 300);
      // INPROG google analytics
      emitPageView(`/checkout/teams/${stage}`, 'Checkout Teams - Invoice');
    }

    else if (stage === 'wireInvoiceConfirm') {
      setTimeout(() => {
        // console.log('scrolling to #wireInvoiceConfirm...');
        document.getElementById('wireInvoiceConfirm').scrollIntoView({ behavior: 'smooth' });
      }, 300);
      // INPROG google analytics 
      emitPageView(`/checkout/teams/${stage}`, 'Checkout Teams - Success Wire Transfer'); 
    }

  }
  onLicenseTypeChanged(licenseType) { // TODO deprecate
    // console.log('changed license type?', licenseType);
    this.merge({ licenseType });
  }
  onPlanFinalized() { // TODO deprecate
    this.setState({ stage: 'planConfirm' });
    // scroll to top
    // window.scrollTo(0, 0);
  }
  onPlanUnfinalized() { // TODO deprecate
    this.setState({ stage: 'planSelect' });
    // scroll to top
    // window.scrollTo(0, 0);
  }
  mergeInvoiceForm(newData) {
    let invoiceForm = shallowCopy(this.state.invoiceForm, newData);
    // invoiceForm.validate = true;
    this.setState({
      invoiceForm,
      invoiceFormTree: this.validateInvoiceForm(null, invoiceForm)
    });
  }
  mergePlanForm(newData) {
    let planForm = shallowCopy(this.state.planForm, newData);
    planForm.validate = true;
    let total = getTotal(this.state.planForm.licenseType, this.state.planForm.seats, this.state.planForm.coachingPackages, this.state.standardProduct, this.state.premiumProduct, this.state.coachingProduct);
    this.setState({
      planForm,
      planFormTree: this.validatePlanForm(null, planForm),
      total,
    });
  }
  validateInvoiceForm(attribute, newData) {
    // console.log('what are we merging?', { attribute, newData });
    let data = newData ? newData : this.state.invoiceForm;

    // if attribute is provided, return empty string or CSS class to add to invalid field
    if (attribute) {
      if (this.state.invoiceForm.validate) {
        // check whether attribute inside validation tree is valid, if not return 'invalid' CSS class
        if (this.state.invoiceFormTree && this.state.invoiceFormTree[attribute] && this.state.invoiceFormTree[attribute].length > 0) {
          return 'invalid';
        }
        return '';
      } else {
        return '';
      }
    } else {
      // generate validation tree
      let licenseTypeValidation = validation.validateInt(data.licenseType) ? null : 'Company plan selection is required.'

      let parsedSeats = parseInt(data.seats);
      let seats = null;

      if (data.licenseType === enums.CompanyOnboarding.LicenseType.PMFoundations) {
        if (isNaN(parsedSeats) || parsedSeats < 1 || parsedSeats > 500) {
          seats = 'Invalid number of seats.';
        }
      }
      else if (data.licenseType === enums.CompanyOnboarding.LicenseType.Premium) {
        if (isNaN(parsedSeats) || parsedSeats < 1 || parsedSeats > 500) {
          seats = 'Invalid number of seats.';
        }
      }

      let tree = {
        name: validation.validateString(data.name) ? null : 'Name is required.',
        email: validation.validateEmail(data.email) ? null : 'E-mail address is required.',
        companyName: validation.validateString(data.companyName) ? null : 'Company name is required.',
        companyAddress: validation.validateString(data.companyAddress) ? null : 'Company address is required.',
        seats: seats,
        licenseType: licenseTypeValidation,
      };
      return tree;
    }
  }
  validatePlanForm(attribute, newData) {
    let data = newData ? newData : this.state.planForm;
    // if attribute is provided, return empty string or CSS class to add to invalid field
    if (attribute) {
      if (this.state.planForm.validate) {
        // check whether attribute inside validation tree is valid, if not return 'invalid' CSS class
        if (this.state.planFormTree && this.state.planFormTree[attribute] && this.state.planFormTree[attribute].length > 0) {
          return 'invalid';
        }
        return '';
      } else {
        // console.log('will not validate this yet...');
        return '';
      }
    } else {
      // generate validation tree
      let tree = {
        seats: null,
      };

      let seats = parseInt(data.seats);
      if (isNaN(seats) || seats < 1 || seats > 500) {
        tree.seats = 'Invalid number of seats.';
      }
      // console.log('we have calculated planForm validation tree.', tree);

      return tree;
    }
  }
  async componentDidMount() {
    try {
      // console.log('[CompanyOnboarding] Handling componentDidMount()...');
      // fetch Products
      let products = await getProductsTeams();
      // console.log('Fetched products in componentDidMount()', products);
      let standardProduct = { id: null, amount: 0 };
      let premiumProduct = { id: null, amount: 0 };
      let coachingProduct = { id: null, amount: 0 };

      for (let p of products) {
        if (p.type === enums.CompanyProduct.Type.CompanySeat) {
          if (p.licenseType === enums.CompanyOnboarding.LicenseType.PMFoundations) {
            standardProduct = p;
            // console.log('fetched standard product.', standardProduct);
          }
          else if (p.licenseType === enums.CompanyOnboarding.LicenseType.Premium) {
            premiumProduct = p;
            // console.log('fetched premium product.', premiumProduct);
          }
        }
        else if (p.type === enums.CompanyProduct.Type.CoachingPackage) {
          coachingProduct = p;
          // console.log('fetched coaching package product.', coachingProduct);
        }
      }

      let total = getTotal(this.state.planForm.licenseType, this.state.planForm.seats, this.state.planForm.coachingPackages, standardProduct, premiumProduct, coachingProduct);

      // console.log('what are we setting in componentDidMount()?', { isLoading: false, products, standardPrice, premiumPrice, coachingPrice });
      this.setState({
        // isLoading: false,
        stage: 'planSelect',
        products,
        standardProduct,
        premiumProduct,
        coachingProduct,
        total,
      });
    } catch(e) {
      console.error('[CompanyOnboarding] Error handling componentDidMount().', e);
    }
  }
  async submit() { 
    try {
      console.log('submit() hit.');

      // ev.preventDefault();
      let invoiceForm = this.state.invoiceForm;
      let planForm = this.state.planForm;
      invoiceForm.validate = true;

      // attempt validation
      let invoiceFormTree = this.validateInvoiceForm(null, invoiceForm);
      console.log('we have a validation tree', invoiceFormTree);
      let isHealthy = validation.isTreeHealthy(invoiceFormTree);
      invoiceForm.isHealthy = isHealthy;

      this.setState({
        invoiceForm,
        invoiceFormTree,
        validate: true,
      });

      if (isHealthy) {
        let data = {
          name: invoiceForm.name,
          email: invoiceForm.email,
          companyName: invoiceForm.companyName,
          companyAddress: invoiceForm.companyAddress,
          companyVAT: invoiceForm.companyVAT,
          paymentMethod: parseInt(invoiceForm.paymentMethod),

          licenseType: planForm.licenseType,
          seats: parseInt(planForm.seats),
          coachingPackages: planForm.coachingPackages,

        };
        // console.log('we have a valid form.', data);
        // this.setState({ isLoading: true });
        // if (window) {
        //   // window.scrollTo(0, 0);
        // }

        // console.log('we have something to send', data);

        // set to 'submitted' so we can disable the submit button
        this.setState({
          isSubmitted: true,
        });

        let result = await submitCompanyOnboarding2(data);
        console.log('done with submission, got response from server.', result);

        // if result was stripe flow session
        if (result.stripeCheckoutSessionId) {
          this.setState({
            stripeCheckoutSessionId: result.stripeCheckoutSessionId,
          });
          return;
        }
        // if result is a wire transfer invoice confirmation
        else if (result.companyInvoiceIdString) {
          // this.setState({});
          // console.log('we generated invoice in backend, congratulations');


          // INPROG send this event to google analytics
          console.log('we should send success event to google analytics from here.');
          let total = getTotal(this.state.planForm.licenseType, this.state.planForm.seats, this.state.planForm.coachingPackages, this.state.standardProduct, this.state.premiumProduct, this.state.coachingProduct);
          console.log('calculated total.', total);
          await emitTeamsWireInvoiceGenerated(total.total, this.state.invoiceForm.email, this.state.planForm.licenseType, parseInt(this.state.planForm.seats));

          this.navigate('wireInvoiceConfirm');
          return;
        }

        // weird state - no idea what's going on
        this.setState({
          isLoading: false,
        });

      } else {
        console.log('Invalid form.', { invoiceForm, invoiceFormTree });
        //TODO display error message?
      }
    } catch(e) {
      console.error(`Error handling onSubmit().`, e);
    }
  }

  render() {


    // did we get stripeCheckoutSessionId?
    if (this.state.stripeCheckoutSessionId) {
      stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: this.state.stripeCheckoutSessionId
      }).then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        if (result.error) {
          console.error('Error inside Stripe Checkout Session', result.error.message);
          console.log(result);
        }
      });
    }

    if (this.state.stage === 'loading') {
      return (
        <div>
          <div className='pi2container'>
            <div className='pi2content'>
              <LoadingCenter />
            </div>
          </div>
        </div>
      );
    }

    else if (this.state.stage === 'planSelect') {
      return (
        <div>
          {/* <NewPurchaseFlowCloud /> */}
          <div className=''>
            <div className='tw:text-xl'>
              <div className='newPurchaseTeamsPricing' id='newPurchaseTeamsPricing'>
                <PlanSelectPage
                  standardProduct={this.state.standardProduct}
                  premiumProduct={this.state.premiumProduct}
                  coachingProduct={this.state.coachingProduct}
                  planForm={this.state.planForm}
                  planFormTree={this.state.planFormTree}
                  mergePlanForm={this.mergePlanForm}
                  total={this.state.total}
                  isHealthy={validation.isTreeHealthy(this.state.planFormTree)}
                  navigate={this.navigate}
                  validate={this.validatePlanForm}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    else if (this.state.stage === 'planConfirm') {
      return (
        <PlanConfirmPage
          planForm={this.state.planForm}
          mergePlanForm={this.mergePlanForm}
          validate={this.validatePlanForm}
          navigate={this.navigate}
          total={this.state.total}
        />
      );
    }

    else if (this.state.stage === 'invoice') {
      return (
        <InvoiceForm
          validate={this.validateInvoiceForm}
          form={this.state.invoiceForm}
          merge={this.mergeInvoiceForm}
          total={this.state.total}
          // navigate={this.navigate}
          submit={this.submit}
          isSubmitted={this.state.isSubmitted}
        />
      );
    }

    else if (this.state.stage === 'wireInvoiceConfirm') {
      // <CompanyOnboarding2ThankYouWire email={this.state.form.email} />
      return (
        <div>
          <div className='pi2container'>
            <div className='pi2content'>
              <div className='wireInvoiceConfirm' id='wireInvoiceConfirm'>
                <CompanyOnboarding2ThankYouWire
                  email={this.state.invoiceForm.email}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
    
    // are we done with stripe checkout flow?
    let status = getQueryAttribute(window.location.search, 's');
    if (status === 's') {
      //console.log('looks like we are done?');
      let email = getQueryAttribute(window.location.search, 'e');
      // console.log('passed e-mail?', email);
      let invoiceId = getQueryAttribute(window.location.search, 'i');
      // console.log('passed invoice identifier?', invoiceId)
      return <CompanyOnboardingThankYouStripe email={email} invoiceId={invoiceId} />;
    }

    // onboarding form / plan selection or billing information

  }
}


export default CompanyOnboarding2;


// <PaymentMethodSelect value={this.state.form.paymentMethod} onChange={paymentMethod => this.merge({ paymentMethod })}/>
class PaymentMethodSelect extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {

    let message = '';
    if (this.props.value === enums.CompanyOnboarding.PaymentMethod.Stripe) {
      // message = 'We will take you to a credit card page to download your invoice and complete your payment.';
      message = 'Enter your credit card information next';
    }
    else if (this.props.value === enums.CompanyOnboarding.PaymentMethod.WireTransfer) {
      // message = 'We will include wire transfer details on the invoice generated on the next page.';
      message = 'Generate an invoice and see details on how to complete wire transfer';
    }

    return (
      <div className="formRow">
        <div className='paymentMethodSelect'>
          <div className='left'>
            <div
              className={this.props.value === enums.CompanyOnboarding.PaymentMethod.Stripe ? 'item active' : 'item'}
              onClick={() => this.props.onChange(enums.CompanyOnboarding.PaymentMethod.Stripe)} >
              <div className='lefty'>
                <div className={this.props.value === enums.CompanyOnboarding.PaymentMethod.Stripe ? 'radioButton active' : 'radioButton'} />
              </div>
              <div className='righty'>
                <label>Credit card</label>
              </div>
            </div>
            <div
              className={this.props.value === enums.CompanyOnboarding.PaymentMethod.WireTransfer ? 'item active' : 'item'}
              onClick={() => this.props.onChange(enums.CompanyOnboarding.PaymentMethod.WireTransfer)} >
              <div className='lefty'>
                <div className={this.props.value === enums.CompanyOnboarding.PaymentMethod.WireTransfer ? 'radioButton active' : 'radioButton'} />
              </div>
              <div className='righty'>
                <label>Wire transfer</label>
              </div>
            </div>
          </div>
  
          <div className='right'>
            <p>{message}</p>
          </div>
          
        </div>
      </div>
    );
  }
}


// <LicenseTypeSelect value={this.state.form.licenseType} onChange={this.onLicenseTypeChanged} />
class LicenseTypeSelect extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className='licenseTypeSelect2 tw:flex tw:flex-row tw:items-stretch tw:justify-between'>
        <div
          className={
            (this.props.value === enums.CompanyOnboarding.LicenseType.PMFoundations ? 'item' : 'item')
            + ` tw:flex tw:flex-col tw:items-stretch tw:rounded-none tw:p-0 tw:max-w-xl`
          }
          onClick={() => {
            this.props.onChange({ licenseType: enums.CompanyOnboarding.LicenseType.PMFoundations });
            this.props.navigate('planConfirm');
          }}
        >
          <div className="tw:flex tw:flex-col tw:items-center tw:font-bold tw:text-white tw:bg-[#96004b] tw:py-4 tw:px-0">
            <h3 className="tw:font-['Poppins'] tw:text-base tw:leading-9 tw:uppercase tw:tracking-widest">Team {this.props.standardProduct.name}</h3>
            <h4 className="tw:font-['Poppins'] tw:font-bold tw:text-4xl tw:leading-[48px]">$1499</h4>
            <h5 className="tw:font-['Nunito_Sans'] tw:text-base tw:leading-5">Per seat</h5>
          </div>
          <div className="tw:grow tw:flex tw:flex-col tw:items-center tw:p-8 tw:gap-4 tw:bg-white">
            <button className='pi4button tw:bg-[#96004b] tw:rounded-md tw:py-2.5 tw:px-4'>SELECT THIS PLAN</button>
            <p className="tw:block tw:xl:hidden tw:h-12 tw:font-['Nunito_Sans'] tw:font-bold tw:text-[#96004b] tw:text-lg tw:leading-5 tw:tracking-tight tw:text-center">
              Access to our PM Foundations Course
            </p>
            <p className="tw:hidden tw:xl:block tw:h-18 tw:font-['Nunito_Sans'] tw:font-bold tw:text-[#96004b] tw:text-lg tw:leading-5 tw:tracking-tight tw:text-center">
              Get started with our PM Foundations course, perfect for new Product Managers or those early in their careers.
            </p>
            <div className="tw:flex tw:flex-col tw:justify-start tw:items-start tw:gap-4">
              <div className="tw:flex tw:flex-row tw:gap-2">
                <div className="tw:text-[#96004b] tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-none">+</div>
                <div className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">Access to just our Product Management Foundations course</div>
              </div>
              <div className="tw:flex tw:flex-row tw:gap-2">
                <div className="tw:text-[#96004b] tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-none">+</div>
                <div className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">Certificate of completion for students</div>
              </div>
              <div className="tw:flex tw:flex-row tw:gap-2">
                <div className="tw:text-[#96004b] tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-none">+</div>
                <div className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">On demand progress reporting for company managers</div>
              </div>
              <div className="tw:flex tw:flex-row tw:gap-2">
                <div className="tw:text-[#96004b] tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-none">+</div>
                <div className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">One year access to course</div>
              </div>
              <div className="tw:flex tw:flex-row tw:gap-2">
                <div className="tw:text-[#96004b] tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-none">+</div>
                <div className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">Optional remote coaching for team members through PM Foundations</div>
              </div>
            </div>
          </div>
        </div>
        
        <div
          className={
            (this.props.value === enums.CompanyOnboarding.LicenseType.Premium ? 'item' : 'item')
            + ` tw:flex tw:flex-col tw:items-stretch tw:rounded-none tw:p-0 tw:max-w-xl`
          }
          onClick={() => {
            this.props.onChange({ licenseType: enums.CompanyOnboarding.LicenseType.Premium });
            this.props.navigate('planConfirm');
          }}
        >
          <div className="tw:flex tw:flex-col tw:items-center tw:font-bold tw:text-white tw:bg-[#76003b] tw:py-4 tw:px-0">
            <h3 className="tw:font-['Poppins'] tw:text-base tw:leading-9 tw:uppercase tw:tracking-widest">Team {this.props.premiumProduct.name}</h3>
            <h4 className="tw:font-['Poppins'] tw:font-bold tw:text-4xl tw:leading-[48px]">$1999</h4>
            <h5 className="tw:font-['Nunito_Sans'] tw:text-base tw:leading-5">Per seat</h5>
          </div>
          <div className="tw:grow tw:flex tw:flex-col tw:items-center tw:p-8 tw:gap-4 tw:bg-white">
            <button className='pi4button tw:bg-[#76003b] tw:rounded-md tw:py-2.5 tw:px-4'>SELECT THIS PLAN</button>
            <p className="tw:block tw:xl:hidden tw:h-12 tw:font-['Nunito_Sans'] tw:font-bold tw:text-[#76003b] tw:text-lg tw:leading-5 tw:tracking-tight tw:text-center">
              Everything in the Foundations Plan, plus...
            </p>
            <p className="tw:hidden tw:xl:block tw:h-18 tw:font-['Nunito_Sans'] tw:font-bold tw:text-[#76003b] tw:text-lg tw:leading-5 tw:tracking-tight tw:text-center">
              Expand your Product skills with access to all our Deep Dive courses.
            </p>
            <div className="tw:flex tw:flex-col tw:justify-start tw:items-start tw:gap-4">
              <div className="tw:flex tw:flex-row tw:gap-2">
                <div className="tw:text-[#96004b] tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-none">+</div>
                <div className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">Access to our Product Management Foundations course and all Deep Dives</div>
              </div>
              <div className="tw:flex tw:flex-row tw:gap-2">
                <div className="tw:text-[#96004b] tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-none">+</div>
                <div className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">Certificate of completion for students</div>
              </div>
              <div className="tw:flex tw:flex-row tw:gap-2">
                <div className="tw:text-[#96004b] tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-none">+</div>
                <div className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">On demand progress reporting for company managers</div>
              </div>
              <div className="tw:flex tw:flex-row tw:gap-2">
                <div className="tw:text-[#96004b] tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-none">+</div>
                <div className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">One year access to courses</div>
              </div>
              <div className="tw:flex tw:flex-row tw:gap-2">
                <div className="tw:text-[#96004b] tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-none">+</div>
                <div className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">Optional remote coaching for team members through PM Foundations</div>
              </div>
            </div>
          </div>
        </div>

        <div className='item tw:flex tw:flex-col tw:items-stretch tw:rounded-none tw:p-0 tw:max-w-xl'>
          <div className="tw:flex tw:flex-col tw:items-center tw:font-bold tw:text-white tw:bg-[#9a1638] tw:py-4 tw:px-0">
            <h3 className="tw:font-['Poppins'] tw:text-base tw:leading-9 tw:uppercase tw:tracking-widest">Enterprise</h3>
            <h4 className="tw:font-['Poppins'] tw:font-bold tw:text-4xl tw:tracking-tighter tw:leading-[48px]">Custom Pricing</h4>
            <h5 className="tw:font-['Nunito_Sans'] tw:text-base tw:leading-5">Unlimited seats for a flat rate</h5>
          </div>
          <div className="tw:grow tw:flex tw:flex-col tw:items-center tw:p-8 tw:gap-4 tw:bg-white">
            <a href='mailto:info@productinstitute.com'>
              <button className='pi4button tw:bg-[#9a1638] tw:rounded-md tw:py-2.5 tw:px-4'>CONTACT US</button>
            </a>
            <p className="tw:block tw:xl:hidden tw:h-12 tw:font-['Nunito_Sans'] tw:font-bold tw:text-[#9a1638] tw:text-lg tw:leading-5 tw:tracking-tight tw:text-center">
              Everything in the Foundations Plan, plus...
            </p>
            <p className="tw:hidden tw:xl:block tw:h-18 tw:font-['Nunito_Sans'] tw:font-bold tw:text-[#9a1638] tw:text-lg tw:leading-5 tw:tracking-tight tw:text-center">
              Level up your entire company with our unlimited license plan.
            </p>
            <div className="tw:flex tw:flex-col tw:justify-start tw:items-start tw:gap-4">
              <div className="tw:flex tw:flex-row tw:gap-2">
                <div className="tw:text-[#96004b] tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-none">+</div>
                <div className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">Unlimited licenses for the duration of the term</div>
              </div>
              <div className="tw:flex tw:flex-row tw:gap-2">
                <div className="tw:text-[#96004b] tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-none">+</div>
                <div className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">Access to all courses, and any new courses published while license is active</div>
              </div>
              <div className="tw:flex tw:flex-row tw:gap-2">
                <div className="tw:text-[#96004b] tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-none">+</div>
                <div className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">Consulting on Training Roll Out</div>
              </div>
              <div className="tw:flex tw:flex-row tw:gap-2">
                <div className="tw:text-[#96004b] tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-none">+</div>
                <div className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">On demand reporting for company managers</div>
              </div>
              <div className="tw:flex tw:flex-row tw:gap-2">
                <div className="tw:text-[#96004b] tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-none">+</div>
                <div className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">Optional Coach-the-Coach program through PM Foundations, to enable training at scale</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

// <CompanyOnboardingThankYouStripe email={email} />
class CompanyOnboardingThankYouStripe extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <main className="thanks">
        {/* <div className="logo">
          <img src="https://pi2-web.s3.amazonaws.com/img/productinstitute-logo-color.svg" alt="Product Institute" />
        </div> */}
        <img src="https://pi2-web.s3.amazonaws.com/img/Group%209.png" className="thanks--bkg-img-1" alt="" />
        {/* <img src="https://pi2-web.s3.amazonaws.com/img/Group%2010.png" className="thanks--bkg-img-2" alt="" /> */}
        <div className="thanks--text-holder contactUsThanks">
          <img src="https://pi2-web.s3.amazonaws.com/img/pi-cat_head%20white_final_CS5.png" alt="" />
          <h1>Thank you!</h1>
          <h2 className='left'>An invoice has been emailed to <a href={this.props.email}>{this.props.email}</a>.</h2>
          <p className='left'>
            Look out for onboarding instruction sent to your email within 48 hours.
            <br />
            <br />
            We’re so glad you've chosen Product Institute!
          </p>
        </div>
      </main>
    );
  }
}


function getTotal(licenseType, seats, coachingPackages, standardProduct, premiumProduct, coachingProduct) {

  let total = 0;
  let totalWithoutDiscount = 0;
  let discount = 0;
  let discountLabels = [];

  let items = [];

  let seatProduct = (licenseType === enums.CompanyOnboarding.LicenseType.Premium) ? premiumProduct : standardProduct;

  if (seats > 0) {
    let seatAmount = seats * seatProduct.price.amount;

    // INPROG disable discount calculator for now
    let discountPercent = 0;
    // if (seats > 20 && seats <= 50) { 
    //   discountPercent = 10;
    //   discountLabels.push('seats10');
    // }
    // else if (seats > 50) { 
    //   discountPercent = 15;
    //   discountLabels.push('seats15');
    // }

    let discountAmount = seatAmount * discountPercent / 100;
    let amount = seatAmount - discountAmount;

    total += seatAmount - discountAmount;
    totalWithoutDiscount += seatAmount;
    discount += discountAmount;

    items.push({
      name: seatProduct.name,
      description: seatProduct.description,
      type: enums.CompanyProduct.Type.CompanySeat,
      price: seatProduct.price.amount,
      quantity: seats,
      total: amount,
      totalWithoutDiscount: seatAmount,
      discount: discountAmount,
      discountPercent,
      thumbnail: seatProduct.thumbnail,
    });
  }

  if (coachingPackages > 0) {
    let coachingTotal = coachingProduct.price.amount * coachingPackages;
    total += coachingTotal;
    totalWithoutDiscount += coachingTotal;
    items.push({
      name: coachingProduct.name,
      description: coachingProduct.description,
      type: enums.CompanyProduct.Type.CoachingPackage,
      price: coachingProduct.price.amount,
      quantity: coachingPackages,
      total: coachingTotal,
      totalWithoutDiscount: coachingTotal,
      discount: 0,
      discountPercent: 0,
      thumbnail: coachingProduct.thumbnail,
    });
  }

  // console.log('we calculated total.', {
  //   items,
  //   total,
  //   totalWithoutDiscount,
  //   discount,
  //   discountLabels,
  // });

  return {
    items,
    total,
    totalWithoutDiscount,
    discount,
    discountLabels,
  };
}

// <TotalSummary total={} navigate={this.props.navigate} />
class TotalSummary extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    // let total = getTotal(this.props.form.licenseType, this.props.form.seats, this.props.form.coachingPackages, this.props.standardProduct, this.props.premiumProduct, this.props.coachingProduct);
    let total = this.props.total;

    let title = 'Total (1 Selected Item)';
    if (total.items.length === 2) {
      title = 'Total (2 Selected Items)';
    }

    let segment = '';
    if (total.discount > 0) {
      segment = (
        <div>
          <span className='couseSelectTotalAmount'>
            <span className='couseSelectTotalAmountBeforeDiscount'>
              {toPrettyDollarsRound(total.totalWithoutDiscount)}
            </span>
            &nbsp;
            <span>{toPrettyDollarsRound(total.total)}</span>
          </span>
          <p className='courseSelectTotalDiscountText'>
            You'll save {toPrettyDollarsRound(total.discount)} on your purchase with our bulk discount!
          </p>
        </div>
      );
    }
    else {
      segment = (
        <div>
          <span className='couseSelectTotalAmount'>
            {toPrettyDollarsRound(total.total)}
          </span>
        </div>
      );
    }

    return (
      <div>
        <div className='courseSelectTotalContainer'>
          <div className='courseSelectTotal tw:px-16'>
            <div className='courseSelectTotalLeft'>
              {/*
              <h2>{title}</h2>
              {segment}
              /**/}
            </div>
            <div className='courseSelectTotalRight'>
              <div>
                <button
                  className='pi4button pi4buttonLarge'
                  onClick={() => this.props.navigate('planConfirm')}
                >Continue to Checkout</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  }
}

// <PlanSelectPage
//   form={this.state.form}
//   onLicenseTypeChanged={this.onLicenseTypeChanged}
//   standardProduct={}
//   premiumProduct={}
//   coachingProduct={}
//   merge={this.merge}
//   onPlanFinalized={this.onPlanFinalized}
// />
class PlanSelectPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validationMessage: '',
      isValid: true,
    };
    this.onSeatsChanged = this.onSeatsChanged.bind(this);
    this.onLicenseTypeChanged = this.onLicenseTypeChanged.bind(this);
  }
  onSeatsChanged(e) {
    let seats = e.target.value;
    this.props.merge({ seats });
    this.validate(this.props.form.licenseType, seats)
  }
  onLicenseTypeChanged(licenseType) {
    this.props.onLicenseTypeChanged(licenseType);
    this.validate(licenseType, this.props.form.seats);
  }

  render() {
    const iconcheck = (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1182_489)">
          <path d="M10 0C4.47719 0 0 4.47719 0 10C0 15.5228 4.47719 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47719 15.5228 0 10 0ZM14.7641 6.92594L9.76406 14.4259C9.66404 14.576 9.53197 14.702 9.37736 14.7948C9.22275 14.8877 9.04947 14.945 8.87 14.9628C8.83013 14.9668 8.79007 14.9688 8.75 14.9688C8.58994 14.9688 8.43145 14.9373 8.28357 14.876C8.13569 14.8148 8.00131 14.725 7.88812 14.6119L4.13812 10.8619C4.02494 10.7487 3.93516 10.6143 3.87391 10.4664C3.81265 10.3186 3.78113 10.1601 3.78113 10C3.78113 9.83994 3.81265 9.68144 3.87391 9.53356C3.93516 9.38568 4.02494 9.25131 4.13812 9.13813C4.25131 9.02494 4.38567 8.93516 4.53355 8.87391C4.68143 8.81265 4.83993 8.78112 5 8.78112C5.16007 8.78112 5.31857 8.81265 5.46645 8.87391C5.61433 8.93516 5.74869 9.02494 5.86188 9.13813L8.56062 11.8369L12.7359 5.57406C12.824 5.43921 12.9378 5.32315 13.071 5.23257C13.2041 5.14199 13.3539 5.0787 13.5117 5.04635C13.6694 5.014 13.832 5.01323 13.9901 5.04409C14.1481 5.07495 14.2985 5.13682 14.4325 5.22614C14.5665 5.31546 14.6814 5.43044 14.7707 5.56445C14.86 5.69846 14.9219 5.84884 14.9527 6.0069C14.9835 6.16495 14.9827 6.32755 14.9504 6.48529C14.918 6.64304 14.8547 6.79281 14.7641 6.92594Z" fill="#C62F75" />
        </g>
        <defs>
          <clipPath id="clip0_1182_489">
            <rect width="20" height="20" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    );

    return (
      <section className='planSelectPage' id='planSelectPage'>

        <div className="tw:flex tw:flex-col tw:items-center tw:p-16 tw:gap-10 tw:bg-[#edeff6]">

          <div>

            <h2 className="tw:text-center tw:text-black tw:text-base tw:leading-9 tw:font-bold tw:tracking-widest tw:uppercase tw:m-0">Choose Your Team Plan</h2>

            <p className="tw:text-center">
              Ready to get started on your product management journey?
              To begin, choose a plan that best suits your team.
            </p>

          </div>

          <div>
            <LicenseTypeSelect
              value={this.props.planForm.licenseType}
              onChange={this.props.mergePlanForm}
              standardProduct={this.props.standardProduct}
              premiumProduct={this.props.premiumProduct}
              navigate={this.props.navigate}
            />
          </div>

        </div>

        <div className="tw:flex tw:flex-col tw:items-stretch tw:p-4 tw:gap-0">

          <div className="tw:flex-col tw:justify-start tw:items-center tw:inline-flex tw:p-4">
            <div className="tw:text-black tw:text-base tw:font-bold tw:font-['Poppins'] tw:uppercase tw:leading-9 tw:tracking-wider">
              Compare plans
            </div>
          </div>

          <div className="tw:flex tw:flex-col tw:items-stretch tw:p-0 tw:gap-0 tw:bg-white">

              <div className="tw:flex tw:flex-row tw:mx-auto tw:justify-center tw:text-[#1f1f1f] tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-10">
                <div className="tw:flex tw:w-64 tw:h-64 tw:xl:h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-end tw:xl:self-center tw:pb-4 tw:xl:pb-0">
                    Items
                  </div>
                </div>
                <div className="tw:flex tw:w-16 tw:xl:w-64 tw:h-64 tw:xl:h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797] tw:text-left tw:xl:text-center">
                  <div className="tw:flex-none tw:xl:self-center tw:w-64 tw:pl-4 tw:xl:pl-0 tw:-rotate-90 tw:translate-y-[256px] tw:xl:translate-y-[0] tw:origin-top-left tw:leading-[64px] tw:xl:rotate-0 tw:xl:leading-none">
                    Individual
                  </div>
                </div>
                <div className="tw:flex tw:w-16 tw:xl:w-64 tw:h-64 tw:xl:h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797] tw:text-left tw:xl:text-center">
                  <div className="tw:flex-none tw:xl:self-center tw:w-64 tw:pl-4 tw:xl:pl-0 tw:-rotate-90 tw:translate-y-[256px] tw:xl:translate-y-[0] tw:origin-top-left tw:leading-[64px] tw:xl:rotate-0 tw:xl:leading-none">
                    Team Foundations
                  </div>
                </div>
                <div className="tw:flex tw:w-16 tw:xl:w-64 tw:h-64 tw:xl:h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797] tw:text-left tw:xl:text-center">
                  <div className="tw:flex-none tw:xl:self-center tw:w-64 tw:pl-4 tw:xl:pl-0 tw:-rotate-90 tw:translate-y-[256px] tw:xl:translate-y-[0] tw:origin-top-left tw:leading-[64px] tw:xl:rotate-0 tw:xl:leading-none">
                    Team Deep Dive
                  </div>
                </div>
                <div className="tw:flex tw:w-16 tw:xl:w-64 tw:h-64 tw:xl:h-12 tw:border-solid tw:border-0 tw:border-t tw:border-[#979797] tw:text-left tw:xl:text-center">
                  <div className="tw:flex-none tw:xl:self-center tw:w-64 tw:pl-4 tw:xl:pl-0 tw:-rotate-90 tw:translate-y-[256px] tw:xl:translate-y-[0] tw:origin-top-left tw:leading-[64px] tw:xl:rotate-0 tw:xl:leading-none">
                    Enterprise
                  </div>
                </div>
              </div>

              <div className="tw:flex tw:flex-row tw:mx-auto tw:justify-center tw:text-black tw:text-xs tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">
                <div className="tw:flex tw:justify-start tw:w-64 tw:h-auto tw:py-2 tw:pr-2 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center tw:w-full">
                    One year access to courses
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
              </div>

              <div className="tw:flex tw:flex-row tw:mx-auto tw:justify-center tw:text-black tw:text-xs tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">
                <div className="tw:flex tw:justify-start tw:w-64 tw:h-auto tw:py-2 tw:pr-2 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center tw:w-full">
                    Course certificate on completion
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
              </div>

              <div className="tw:flex tw:flex-row tw:mx-auto tw:justify-center tw:text-black tw:text-xs tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">
                <div className="tw:flex tw:justify-start tw:w-64 tw:h-auto tw:py-2 tw:pr-2 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center tw:w-full">
                    Password-less login for all users
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
              </div>

              <div className="tw:flex tw:flex-row tw:mx-auto tw:justify-center tw:text-black tw:text-xs tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">
                <div className="tw:flex tw:justify-start tw:w-64 tw:h-auto tw:py-2 tw:pr-2 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center tw:w-full">
                    Email Support during Weekdays
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
              </div>

              <div className="tw:flex tw:flex-row tw:mx-auto tw:justify-center tw:text-black tw:text-xs tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">
                <div className="tw:flex tw:justify-start tw:w-64 tw:h-auto tw:py-2 tw:pr-2 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center tw:w-full">
                    Credit Card Payments (3% fee)
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
              </div>

              <div className="tw:flex tw:flex-row tw:mx-auto tw:justify-center tw:text-black tw:text-xs tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">
                <div className="tw:flex tw:justify-start tw:w-64 tw:h-auto tw:py-2 tw:pr-2 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center tw:w-full">
                    Wire &amp; ACH Payments
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
              </div>

              <div className="tw:flex tw:flex-row tw:mx-auto tw:justify-center tw:text-black tw:text-xs tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">
                <div className="tw:flex tw:justify-start tw:w-64 tw:h-auto tw:py-2 tw:pr-2 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center tw:w-full">
                    Invoices, Contracting, and PO
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
              </div>

              <div className="tw:flex tw:flex-row tw:mx-auto tw:justify-center tw:text-black tw:text-xs tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">
                <div className="tw:flex tw:justify-start tw:w-64 tw:h-auto tw:py-2 tw:pr-2 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center tw:w-full">
                    Reporting for Company Managers
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
              </div>

              <div className="tw:flex tw:flex-row tw:mx-auto tw:justify-center tw:text-black tw:text-xs tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">
                <div className="tw:flex tw:justify-start tw:w-64 tw:h-auto tw:py-2 tw:pr-2 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center tw:w-full">
                    Optional Remote Coaching for Teams
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
              </div>

              <div className="tw:flex tw:flex-row tw:mx-auto tw:justify-center tw:text-black tw:text-xs tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">
                <div className="tw:flex tw:justify-start tw:w-64 tw:h-auto tw:py-2 tw:pr-2 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center tw:w-full">
                    License Admin Tools
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
              </div>

              <div className="tw:flex tw:flex-row tw:mx-auto tw:justify-center tw:text-black tw:text-xs tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">
                <div className="tw:flex tw:justify-start tw:w-64 tw:h-auto tw:py-2 tw:pr-2 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center tw:w-full">
                    Reassign up to 10% of licenses purchased within the year to other team members (terms apply)
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
              </div>

              <div className="tw:flex tw:flex-row tw:mx-auto tw:justify-center tw:text-black tw:text-xs tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">
                <div className="tw:flex tw:justify-start tw:w-64 tw:h-auto tw:py-2 tw:pr-2 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center tw:w-full">
                    Access to any new deep dive courses published during license term
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
              </div>

              <div className="tw:flex tw:flex-row tw:mx-auto tw:justify-center tw:text-black tw:text-xs tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">
                <div className="tw:flex tw:justify-start tw:w-64 tw:h-auto tw:py-2 tw:pr-2 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center tw:w-full">
                    Unlimited number of students
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
              </div>

              <div className="tw:flex tw:flex-row tw:mx-auto tw:justify-center tw:text-black tw:text-xs tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">
                <div className="tw:flex tw:justify-start tw:w-64 tw:h-auto tw:py-2 tw:pr-2 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center tw:w-full">
                    Access to ALL courses published during license term
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
              </div>

              <div className="tw:flex tw:flex-row tw:mx-auto tw:justify-center tw:text-black tw:text-xs tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">
                <div className="tw:flex tw:justify-start tw:w-64 tw:h-auto tw:py-2 tw:pr-2 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center tw:w-full">
                    Guidance on company training roll-out
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
              </div>

              <div className="tw:flex tw:flex-row tw:mx-auto tw:justify-center tw:text-black tw:text-xs tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">
                <div className="tw:flex tw:justify-start tw:w-64 tw:h-auto tw:py-2 tw:pr-2 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center tw:w-full">
                    Optional Coach the Coach Training
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
              </div>

              <div className="tw:flex tw:flex-row tw:mx-auto tw:justify-center tw:text-black tw:text-xs tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">
                <div className="tw:flex tw:justify-start tw:w-64 tw:h-auto tw:py-2 tw:pr-2 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center tw:w-full">
                    Priority Support
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-r tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                  </div>
                </div>
                <div className="tw:flex tw:justify-center tw:w-16 tw:xl:w-64 tw:h-auto tw:min-h-12 tw:border-solid tw:border-0 tw:border-t tw:border-[#979797]">
                  <div className="tw:flex-none tw:self-center">
                    {iconcheck}
                  </div>
                </div>
              </div>

          </div>

        </div>

        <div className="courseSelectTotalContainer tw:border-b-0 tw:p-0"></div>

      </section>
    );
  }
}

// <PlanConfirmPage navigate={this.navigate} />
class PlanConfirmPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {

    let courses = [];
    for (let item of this.props.total.items) {

      let priceSegment = '';
      if (item.discount > 0) {
        priceSegment = (
          <h5>
            Price:&nbsp;
            <i>{toPrettyDollars(item.totalWithoutDiscount)}</i>
            &nbsp;
            <strong>{toPrettyDollars(item.total)}</strong>
          </h5>
        );
      }
      else {
        priceSegment = (
          <h5>
            Price:&nbsp;
            <strong>{toPrettyDollars(item.total)}</strong>
          </h5>
        );
      }

      if (item.type === enums.CompanyProduct.Type.CompanySeat) {
        let studentCountLabel = '';
        if (item.quantity === 1 || item.quantity === '1') {
          studentCountLabel = `(1 Student)`;
        } else {
          studentCountLabel = `(${item.quantity} Students)`;
        }
        let discountLabel = '';
        if (item.discountPercent === 10) {
          discountLabel = (
            <span className="courseSelectConfirmTotalDiscount">10% Off</span>
          );
        }
        else if (item.discountPercent === 15) {
          discountLabel = (
            <span className="courseSelectConfirmTotalDiscount">15% Off</span>
          );
        }

        courses.push(
          <div key={item.name} className='courseSelectConfirmItem tw:flex tw:flex-row'>
            <div className='courseSelectConfirmItemLeft tw:flex-none'>
              <img src={item.thumbnail} alt={item.name} />
            </div>
            <div className='courseSelectConfirmItemRight tw:flex-1 tw:flex tw:flex-col tw:justify-start'>
              <div className="tw:flex-none">
                <h4 className="tw:font-['Poppins'] tw:font-bold tw:text-2xl tw:leading-9 tw:text-[#76003b]">{item.name}</h4>
              </div>
              <div className="tw:flex tw:flex-col tw:sm:flex-row">
                <div className="tw:flex-1">
                  <h4 className="tw:font-['Nunito_Sans'] tw:font-bold tw:text-base tw:leading-5 tw:text-black">Quantity</h4>
                  <div className="tw:font-['Poppins'] tw:font-bold tw:text-xl tw:leading-[48px] tw:text-black">
                    <input
                      className={` ${this.props.validate('seats')} tw:font-['Poppins'] tw:font-bold tw:text-xl`}
                      type="number" pattern="[0-9]*" min="1" max="500"
                      value={this.props.planForm.seats}
                      onChange={ev => this.props.mergePlanForm({ seats: ev.target.value })}
                    ></input>
                  </div>
                </div>
                <div className="tw:flex-1">
                  <h4 className="tw:font-['Nunito_Sans'] tw:font-bold tw:text-base tw:leading-5 tw:text-black">Price</h4>
                  <div className="tw:font-['Poppins'] tw:font-bold tw:text-xl tw:leading-[48px] tw:text-black">
                    {toPrettyDollars(item.price)}
                  </div>
                </div>
                <div className="tw:flex-1">
                  <h4 className="tw:font-['Nunito_Sans'] tw:font-bold tw:text-base tw:leading-5 tw:text-black">Total</h4>
                  <div className="tw:font-['Poppins'] tw:font-bold tw:text-xl tw:leading-[48px] tw:text-black">
                    {toPrettyDollars(item.total)}
                  </div>
                </div>
              </div>
              <div className="tw:flex-none tw:font-['Nunito_Sans'] tw:font-normal tw:text-xs tw:leading-5 tw:text-black">
                Invite students via email, after checkout.
              </div>
            </div>
          </div>
        );
      }
      else if (item.type === enums.CompanyProduct.Type.CoachingPackage) {
        let studentCountLabel = '';
        if (item.quantity === 1) {
          studentCountLabel = `(1 Package - 15 Students)`;
        } else {
          studentCountLabel = `(${item.quantity} Packages - ${item.quantity * 15} Students)`;
        }
        courses.push(
          <div key={item.name} className='courseSelectConfirmItem tw:flex tw:flex-row'>
            <div className='courseSelectConfirmItemLeft tw:flex-none'>
              <img src={item.thumbnail} alt={item.name} />
            </div>
            <div className='courseSelectConfirmItemRight tw:flex-1 tw:flex tw:flex-col tw:justify-start'>
              <div className="tw:flex-none">
                <h4 className="tw:font-['Poppins'] tw:font-bold tw:text-2xl tw:leading-9 tw:text-[#76003b]">{item.name}</h4>
              </div>
              <div className="tw:flex tw:flex-col tw:sm:flex-row">
                <div className="tw:flex-1">
                  <h4 className="tw:font-['Nunito_Sans'] tw:font-bold tw:text-base tw:leading-5 tw:text-black">Quantity</h4>
                  <div className="tw:font-['Poppins'] tw:font-bold tw:text-xl tw:leading-[48px] tw:text-black">
                    <input
                      className={` ${this.props.validate('seats')} tw:font-['Poppins'] tw:font-bold tw:text-xl`}
                      type="number" pattern="[0-9]*" min="1" max="500"
                      value={this.props.planForm.seats}
                      onChange={ev => this.props.mergePlanForm({ seats: ev.target.value })}
                    ></input>
                  </div>
                </div>
                <div className="tw:flex-1">
                  <h4 className="tw:font-['Nunito_Sans'] tw:font-bold tw:text-base tw:leading-5 tw:text-black">Price</h4>
                  <div className="tw:font-['Poppins'] tw:font-bold tw:text-xl tw:leading-[48px] tw:text-black">
                    {toPrettyDollars(item.price)}
                  </div>
                </div>
                <div className="tw:flex-1">
                  <h4 className="tw:font-['Nunito_Sans'] tw:font-bold tw:text-base tw:leading-5 tw:text-black">Total</h4>
                  <div className="tw:font-['Poppins'] tw:font-bold tw:text-xl tw:leading-[48px] tw:text-black">
                    {toPrettyDollars(item.total)}
                  </div>
                </div>
              </div>
              <div className="tw:flex-none tw:font-['Nunito_Sans'] tw:font-normal tw:text-xs tw:leading-5 tw:text-black">
                Invite students via email, after checkout.
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <div className='planConfirmPageContainer'>
        <img className="macka" src="https://pi2-web.s3.amazonaws.com/img/macka.png" alt="Product Institute" />
        <div className='pi2container'>
          <div className='pi2content'>
            <div className='planConfirmPage' id='planConfirmPage'>
              <h2>You’re on your way to product excellence.</h2>
              <h3>Your Courses</h3>
              {courses}
            </div>
            <PlanConfirmTotal total={this.props.total} />

            <div className='planConfirmFooter'>
              <button className='pi4button pi4buttonLarge' onClick={() => this.props.navigate('invoice')}>Continue</button>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

// <PlanConfirmTotal total={total} />
class PlanConfirmTotal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let total = this.props.total;
    let items = this.props.total.items;

    // count selected items
    let orderSummary = '';
    if (items.length > 0) {
      if (items.length === 1) {
        orderSummary = `1 Item`;
      }
      else {
        orderSummary = `${items.length} Items`;
      }
    }

    // add discount labels if discounts applied
    let discount = '';
    let discountLabels = [];
    if (total.discountLabels.length > 0) {
      for (let label of total.discountLabels) {
        if (label === 'seats10') {
          discountLabels.push(
            <span key='seats10' className='courseSelectConfirmTotalDiscount'>10% Off Bulk Discount (21+ students)</span>
          );
        }
        else if (label === 'seats15') {
          discountLabels.push(
            <span key='seats15' className='courseSelectConfirmTotalDiscount'>15% Off Bulk Discount (51+ students)</span>
          );
        }
        else {
          console.error('Unknown discount label.', label);
        }
      }
    }
    if (total.discount > 0) {
      discount = (
        <div className='courseSelectConfirmTotalRow'>
          <div className='courseSelectConfirmTotalRowLeft'>
            Discount&nbsp;
            {discountLabels}
          </div>
          <div className='courseSelectConfirmTotalRowRight'>
            -{toPrettyDollars(total.discount)}
          </div>
        </div>
      );
    }

    return (
      <div className='courseSelectConfirmTotal'>
        <div className='courseSelectConfirmTotalRow'>
          <div className='courseSelectConfirmTotalRowLeft'>
            <strong>Order Summary</strong>
          </div>
          <div className='courseSelectConfirmTotalRowRight'>
            <strong>{orderSummary}</strong>
          </div>
        </div>

        <div className='courseSelectConfirmTotalRow'>
          <div className='courseSelectConfirmTotalRowLeft'>
            Subtotal
          </div>
          <div className='courseSelectConfirmTotalRowRight'>
            {toPrettyDollars(total.totalWithoutDiscount)}
          </div>
        </div>

        {discount}

        <div className='courseSelectConfirmTotalRow courseSelectConfirmTotalTotal'>
          <div className='courseSelectConfirmTotalRowLeft'>
            Your Total
          </div>
          <div className='courseSelectConfirmTotalRowRight'>
            {toPrettyDollars(total.total)}
          </div>
        </div>
      </div>
    )
  }
}

class InvoiceForm extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {

    let validate = this.props.validate;
    let form = this.props.form;
    let merge = this.props.merge;


    let continueButton = '';

    if (this.props.isSubmitted) {
      continueButton = (
        <button disabled='disabled' className='pi4button pi4buttonLarge'>Submitting...</button>
      );
    }
    else {
      continueButton = (
        <button className='pi4button pi4buttonLarge' onClick={this.props.submit}>Continue</button>
      );
    }

    return (

      <div className='companyOnboardingInvoiceFormContainer'>
        <img className='macka' src='https://pi2-web.s3.amazonaws.com/img/macka.png' alt='Product Institute' />
        <div className='pi2container'>
          <div className='pi2content'>

            <section className='companyOnboarding2' id='companyOnboardingInvoiceForm'>
              <div className='spacer'></div>
              <div className='content'>
                <h2>Invoice Information</h2>
                <p>
                  Fill out the information below and we’ll generate an invoice for you.
                  {/* <br />
                  Don’t need one after all? You can skip this step. */}
                </p>
                <br />
                <div className='pi2form'>
                  <div className="formRow">
                    <label htmlFor="input-fullname">Invoice made out to</label>
                    <input type='text' className={` ${validate('name')}`} id='input-fullname' name='name' value={form.name} onChange={e => merge({ name: e.target.value })} placeholder='e.g. John Smith' />
                  </div>
                  <div className="formRow">
                    <label htmlFor="input-company-name">Company name</label>
                    <input type='text' className={` ${validate('companyName')}`} id='input-company-name' name='companyName' value={form.companyName} onChange={e => merge({ companyName: e.target.value })} placeholder='e.g. Acme' />
                  </div>
                  <div className="formRow">
                    <label htmlFor="input-message">Company address</label>
                    <textarea className={` ${validate('companyAddress')}`} id='company-address' name='company-address' value={form.companyAddress} onChange={e => merge({ companyAddress: e.target.value })} placeholder="e.g. &#x000D;&#x000A;123 West Court &#x000D;&#x000A;Westbury, NY 11590 &#x000D;&#x000A;United States" style={{ minHeight: '120px' }}/>
                  </div>
                  <div className="formRow">
                    <label htmlFor="input-email">Email</label>
                    <input type='email' className={` ${validate('email')}`} id='input-email' name='email' value={form.email} onChange={e => merge({ email: e.target.value })} placeholder='e.g. jsmith@company.com' />
                  </div>
                  <div className="formRow">
                    <label htmlFor="input-company-vat">VAT number (if applicable)</label>
                    <input type='text' className='' id='input-company-vat' name='input-vat' value={form.companyVAT} onChange={e => merge({ companyVAT: e.target.value })} placeholder='VAT #' />
                  </div>
                  <br />
                  <PlanConfirmTotal total={this.props.total} />
                  <br />
                  <h5>Payment Method</h5>
                  <br />
                  <PaymentMethodSelect value={form.paymentMethod} onChange={paymentMethod => merge({ paymentMethod })} />
                  <br />
                  <br />

                  <div className='linebreak'></div>
                  <br />
                  {/* <p className='legal'>
                    By subscribing for the number of Library Licenses as indicated above, I agree to be bound by the terms of all of the documents and/or policies set forth below, all of which are incorporated together to form a single legally binding agreement between Client and the Product Institute (this single legal agreement is collectively referred to as the "Click-Through Agreement" (the documents below, other than this Invoice which is generated and emailed to Client, can all be found at);
                  </p>
                  <ol className='legal'>
                    <li>the Invoice,</li>
                    <li>the <a href='https://productinstitute.com/p/click-through'>Click-Through License Terms</a></li>
                    <li>the <a href='https://productinstitute.com/p/glossary'>Glossary</a>,</li>
                    <li>the <a href='https://productinstitute.com/p/terms'>End-User Terms &amp; Conditions</a>,</li>
                    <li>the <a href='https://productinstitute.com/p/programpolicies'>Program Policies</a>,</li>
                    <li>the <a href='https://productinstitute.com/p/sla'>SLA</a>,</li>
                    <li>the <a href='https://productinstitute.com/p/privacy'>Privacy Policy</a>.</li>
                  </ol> */}
                  <p className='legal'>
                    By purchasing a “Course,” you shall become a “Client” of the Product Institute and by doing so, shall be bound by the terms of all of the following documents or policies, which are incorporated together to form a single legal agreement that will be binding on you as the Client and the Product Institute (this single legal agreement is collectively referred to as the “Click-Through Agreement”):
                  </p>
                  <ol className='legal'>
                    <li>the Invoice (which is generated upon ordering the Library License(s),</li>
                    <li>the <a href='https://productinstitute.com/p/click-through'>PI Terms</a></li>
                    <li>the <a href='https://productinstitute.com/p/glossary'>Glossary</a>,</li>
                    <li>the <a href='https://productinstitute.com/p/terms'>End-User Terms &amp; Conditions</a>,</li>
                    <li>the <a href='https://productinstitute.com/p/programpolicies'>Program Policies</a>,</li>
                    <li>the <a href='https://productinstitute.com/p/sla'>SLA</a>,</li>
                    <li>the <a href='https://productinstitute.com/p/privacy'>Privacy Policy</a>.</li>
                  </ol>
                  <p className='legal'>
                    The above documents (other than the Invoice which is generated and emailed to Client) can all be found at the following URL: <a href='https://productinstitute.com/p/click-through'>https://productinstitute.com/p/click-through</a>.
                    <br />
                    <br />
                    By purchasing one or more Courses, you on behalf of yourself, or on behalf of your principal if you are acting on behalf of an entity (as listed on the Invoice as a Client), are hereby indicating that you have clicked on the hyperlink associated with each of these above documents or have otherwise accessed each of these particular documents, have reviewed the terms of each of these particular documents, have the authority to enter into the Click-Through Agreement if you are acting on behalf of your principal (as listed on the Invoice) and hereby consent and obligate yourself or your principal (as listed on the Invoice) to all of the terms contained within each of these particular documents – some of which address the following issues: Client responsibilities, intellectual property rights, ownership of data, limitation of liability, limited warranty, indemnification, confidentiality, dispute resolution, service level requirements and branding requirements.
                  </p>
                  <div className="formRow buttonRow">
                    <br />
                    {continueButton}
                    <br />
                    <br />
                  </div>
                </div>

              </div>
              <div className='spacer'></div>
            </section>
            <div style={{ clear: 'both' }}></div>
          </div>
        </div>

      </div>

    );
  }
}
