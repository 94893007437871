
import {useState} from 'react';

export const useStateAtom = (initialState) => {
  let [state, setState] = useState(initialState);
  return (...args) => {
    if (args.length == 0)
      return state;
    // Assign `args[0]` directly to `state` in case getter is called
    // immediately before re-rendering.
    state = args[0];
    return setState(...args);
  };
};
