
import React from 'react';
import TailwindAllClasses from '../../src/pi3-frontend/helpers/TailwindAllClasses.jsx';
import {DateTime} from 'luxon';
import {getManagerCompany} from '../../src/pi3-frontend/ajax.js';
import {getUserInfo} from '../../src/pi3-frontend/ajax.js';
import {useFirstRender} from '../../src/utilities/React.js';
import {useStateAtom} from '../../src/utilities/React/UseStateAtom.js';
import {utils as xlsxUtils}  from 'xlsx-js-style';
import {writeFile as xlsxWriteFile}  from 'xlsx-js-style';

export default function (props) {

  const activeStep = useStateAtom(1);
  const userInfo = useStateAtom(null);
  const managerCompany = useStateAtom(null);
  const companyPlanSelectedID = useStateAtom('');
  const uploadedCSVStudents = useStateAtom([]);

  const handleCSVUpload = (file) => {
    const reader = new FileReader();
    reader.addEventListener('loadend', (loadEvent) => {
      if (reader.error)
        throw reader.error;
      uploadedCSVStudents(
        String(reader.result)
          .split(/\n/g)
          .filter(line => !!String(line).trim())
          .filter(line => line.replace(/[ \t]/g, '') != 'name,email')
          .map((line) => {
            const [name, email] = String(line).trim().split(/,/g);
            return {
              selected: false,
              name: name,
              email: email,
            };
          })
      );
    });
    reader.readAsText(file);
  };

  useFirstRender(async () => {
    userInfo(await getUserInfo());
    managerCompany(await getManagerCompany(userInfo().companyId));

    // @see https://stackoverflow.com/questions/74686380/drag-drop-a-file-anywhere-on-a-page/74687505#74687505
    // @see https://stackoverflow.com/questions/21339924/drop-event-not-firing-in-chrome/21341021#21341021

    let dropDiv = document.createElement('div');
    dropDiv.className = "tw:fixed tw:top-0 tw:right-0 tw:bottom-0 tw:left-0 tw:z-[999999] tw:bg-slate-300 tw:opacity-80 tw:flex tw:place-content-stretch tw:place-items-center";
    dropDiv.innerHTML = `<div class="tw:mx-auto tw:text-black tw:text-4xl tw:font-bold tw:font-['Nunito_Sans'] tw:leading-tight">Drop .CSV</div>`;
    let dropActive = false;

    document.addEventListener('dragenter', (e) => {
      e.stopPropagation();
      e.preventDefault();
    });

    document.addEventListener('dragover', (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (dropActive)
        return;
      dropActive = true;
      document.body.appendChild(dropDiv);
    });

    document.addEventListener('dragend', (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (!dropActive)
        return;
      dropActive = false;
      document.body.removeChild(dropDiv);
    });

    document.addEventListener('dragleave', (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (!dropActive)
        return;
      dropActive = false;
      document.body.removeChild(dropDiv);
    });

    document.addEventListener('drop', (e) => {
      e.preventDefault();
      e.stopPropagation();
      handleCSVUpload(e.dataTransfer.files[0]);
      if (!dropActive)
        return;
      dropActive = false;
      document.body.removeChild(dropDiv);
    });

  });

  const companyPlanSelected = () => managerCompany().plans.find(p => p.id == companyPlanSelectedID());

  return (<>

    <TailwindAllClasses />

    <div>

      <h1 className="tw:text-black tw:text-4xl tw:font-bold tw:font-['Poppins'] tw:leading-10">
        Bulk import new users
      </h1>

      <div className="courseSelectTotalContainer tw:border-b-0 tw:p-0 tw:my-8"></div>

      <div className="tw:flex tw:rounded-lg tw:bg-[#edeff6] tw:text-[#979797]">
        <div
          className={`
            tw:grow tw:shrink tw:basis-0 tw:px-4 tw:text-center tw:text-base tw:font-bold tw:font-['Poppins'] tw:uppercase tw:leading-9 tw:tracking-wider
            ${(activeStep() == 1) ? `tw:bg-[#9a1638] tw:text-white` : ``}
          `}
        >
          Select a plan
        </div>
        <div
          className={`
            tw:grow tw:shrink tw:basis-0 tw:px-4 tw:text-center tw:text-base tw:font-bold tw:font-['Poppins'] tw:uppercase tw:leading-9 tw:tracking-wider
            ${(activeStep() == 2) ? `tw:bg-[#9a1638] tw:text-white` : ``}
          `}
        >
          Upload CSV
        </div>
        <div
          className={`
            tw:grow tw:shrink tw:basis-0 tw:px-4 tw:text-center tw:text-base tw:font-bold tw:font-['Poppins'] tw:uppercase tw:leading-9 tw:tracking-wider
            ${(activeStep() == 3) ? `tw:bg-[#9a1638] tw:text-white` : ``}
          `}
        >
          Confirm students
        </div>
        <div
          className={`
            tw:grow tw:shrink tw:basis-0 tw:px-4 tw:text-center tw:text-base tw:font-bold tw:font-['Poppins'] tw:uppercase tw:leading-9 tw:tracking-wider
            ${(activeStep() == 4) ? `tw:bg-[#9a1638] tw:text-white` : ``}
          `}
        >
          Complete
        </div>
      </div>

      <div className="tw:mt-4">

        {activeStep() == 1 && (<>
          <div>

            <h4 className="tw:text-black tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-9">
              Select a plan
            </h4>

            <select
              value={companyPlanSelectedID()}
              onChange={(e) => { companyPlanSelectedID(e.target.value); }}
              className="tw:relative tw:w-full tw:h-full tw:box-border tw:p-2 tw:text-[#595d64] tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight tw:bg-[#edeff6] tw:border-0 tw:rounded-lg"
            >
              {/* @see https://stackoverflow.com/questions/5805059/make-a-placeholder-for-a-select-box/5859221#5859221 */}
              <option disabled="disabled" hidden="hidden" value="" className="tw:border-0">
                Select plan...
              </option>
              {userInfo()?.companyPlans?.map(companyPlan => (
                <option key={companyPlan.companyPlanId} value={companyPlan.companyPlanId} className="tw:border-0">
                  {companyPlan.companyPlanName}
                </option>
              ))}
            </select>

            <button
              className="tw:mt-4 tw:h-10 tw:px-4 tw:py-2.5 tw:bg-[#edeff6] tw:rounded tw:justify-center tw:items-start tw:gap-2.5 tw:inline-flex tw:border-0 tw:cursor-pointer tw:disabled:cursor-not-allowed"
              disabled={!companyPlanSelectedID() ? 'disabled' : undefined}
              onClick={(e) => {
                activeStep(2);
              }}
            >
              <div className="tw:text-center tw:text-[#595d64] tw:text-xl tw:font-bold tw:font-['Nunito_Sans'] tw:leading-tight">
                Next
              </div>
            </button>

          </div>
        </>)}

        {activeStep() == 2 && (<>
          <div>

            <h2 className="tw:text-black tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-9">
              Upload .CSV
            </h2>

            <div className="tw:mt-4 tw:text-[#1f1f1f] tw:text-base tw:font-bold tw:font-['Poppins'] tw:uppercase tw:leading-9 tw:tracking-wider">
              Step 1
            </div>

            <div
              className="tw:px-10 tw:py-6 tw:bg-[#939aa8] tw:rounded tw:justify-center tw:items-center tw:gap-2.5 tw:flex"
              onClick={(e) => {
                const wb = xlsxUtils.book_new();
                xlsxUtils.book_append_sheet(
                  wb,
                  xlsxUtils.json_to_sheet([
                    {
                      name: 'John Doe',
                      email: 'john.doe@example.com',
                    },
                  ]),
                  'Analytics',
                );
                xlsxWriteFile(wb, `students.csv`);
              }}
            >
              <div className="tw:text-center tw:text-white tw:text-xl tw:font-bold tw:font-['Nunito_Sans'] tw:leading-tight tw:cursor-pointer tw:disabled:cursor-not-allowed">
                Download our Template .CSV
              </div>
            </div>

            <div className="tw:mt-4 tw:text-[#1f1f1f] tw:text-base tw:font-bold tw:font-['Poppins'] tw:uppercase tw:leading-9 tw:tracking-wider">
              Step 2
            </div>

            <div
              className="tw:px-10 tw:py-6 tw:bg-[#939aa8] tw:rounded tw:justify-center tw:items-center tw:gap-2.5 tw:flex tw:cursor-pointer tw:disabled:cursor-not-allowed"
              onClick={(e) => {
                document.getElementById('csvFile').click();
              }}
            >
              <div className="tw:text-center tw:text-white tw:text-xl tw:font-bold tw:font-['Nunito_Sans'] tw:leading-tight">
                <div>
                  Drag and Drop .CSV
                </div>
                <div>
                  or
                </div>
                <div>
                  Browse for file
                </div>
                <input
                  id="csvFile"
                  type="file"
                  className="tw:hidden"
                  onChange={async (e) => {
                    if (!e.target.files)
                      throw new Error('File not found.');
                    handleCSVUpload(e.target.files[0]);
                  }}
                />
              </div>
            </div>

            <div className="tw:my-8 tw:h-px tw:bg-[#d9d9d9]" />

            <div className="tw:p-4 tw:bg-[#edeff6] tw:rounded-xl">

              <div className="tw:text-black tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-9">{companyPlanSelected().name}</div>

              <div className="tw:flex tw:flex-row tw:w-full">
                <div>
                  <div className="tw:text-black tw:text-lg tw:font-bold tw:font-['Nunito_Sans'] tw:leading-loose">
                    {companyPlanSelected().actualUserCount} Enrolled Learners
                  </div>
                  <div className="tw:text-black tw:text-lg tw:font-bold tw:font-['Nunito_Sans'] tw:leading-loose">
                    {companyPlanSelected().totalSeats - companyPlanSelected().actualUserCount}/{companyPlanSelected().totalSeats} available licenses
                  </div>
                  <div className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-loose">
                    {DateTime.fromISO(companyPlanSelected().startsAt).startOf('day').toFormat('LLL d yyyy')}
                    &nbsp;-&nbsp;
                    {DateTime.fromISO(companyPlanSelected().expiresAt).startOf('day').toFormat('LLL d yyyy')}
                  </div>
                  {/*
                  <div className="tw:text-right">
                    <span className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">Metrics 06-2024 </span><span className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">has </span><span className="tw:text-black tw:text-base tw:font-bold tw:font-['Nunito_Sans'] tw:underline tw:leading-tight">5</span><span className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight"> seat transfers avalible</span>
                  </div>
                  /**/}
                </div>
                <div className="tw:pl-32">
                  <div className="tw:text-black tw:text-base tw:font-bold tw:font-['Poppins']">Plan Courses</div>
                  <div className="tw:text-black tw:text-lg tw:font-normal tw:font-['Nunito_Sans'] tw:leading-loose">Metrics</div>
                </div>
              </div>

            </div>

            <button
              className="tw:mt-4 tw:h-10 tw:px-4 tw:py-2.5 tw:bg-[#edeff6] tw:rounded tw:justify-center tw:items-start tw:gap-2.5 tw:inline-flex tw:border-0 tw:cursor-pointer tw:disabled:cursor-not-allowed"
              disabled={uploadedCSVStudents() == 0 ? 'disabled' : undefined}
              onClick={(e) => {
                activeStep(3);
              }}
            >
              <div className="tw:text-center tw:text-[#595d64] tw:text-xl tw:font-bold tw:font-['Nunito_Sans'] tw:leading-tight">
                Next
              </div>
            </button>

          </div>
        </>)}

        {activeStep() == 3 && (<>
          <div>

            <div className="tw:flex tw:justify-start tw:items-center tw:gap-4">
              <div className="tw:w-60 tw:justify-start tw:items-center tw:gap-2 tw:flex">
                <div className="tw:pl-1.5 tw:text-[#979797] tw:text-base tw:font-bold tw:font-['Poppins'] tw:uppercase tw:leading-9 tw:tracking-wider">
                  {uploadedCSVStudents().filter(s => !!s.selected).length}
                </div>
                <div className="tw:text-[#979797] tw:text-base tw:font-bold tw:font-['Poppins'] tw:uppercase tw:leading-9 tw:tracking-wider">
                  Students selected
                </div>
              </div>
              <div className="tw:justify-center tw:items-center tw:flex">
                <div
                  className="tw:text-[#979797] tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:underline tw:leading-tight tw:cursor-pointer tw:disabled:cursor-not-allowed"
                  onClick={(e) => {
                    uploadedCSVStudents(uploadedCSVStudents().filter((s) => !s.selected));
                  }}
                >
                  Delete selected students
                </div>
              </div>
            </div>

            <table className="tw:table tw:w-full tw:border-collapse tw:indent-0">
              <thead>
                <tr className="tw:box-border">
                  <th className="tw:align-middle tw:text-left tw:overflow-hidden tw:p-1.5">
                    <input
                      type="checkbox"
                      checked={uploadedCSVStudents().filter(s => !!s.selected).length == uploadedCSVStudents().length}
                      onChange={(e) => {
                        uploadedCSVStudents(uploadedCSVStudents().map((s) => ({
                          ...s,
                          selected: !!e.target.checked,
                        })));
                      }}
                    />
                  </th>
                  <th className="tw:align-middle tw:text-left tw:overflow-hidden tw:p-1.5">
                    Name
                  </th>
                  <th className="tw:align-middle tw:text-left tw:overflow-hidden tw:p-1.5">
                    Email
                  </th>
                  <th className="tw:align-middle tw:text-left tw:overflow-hidden tw:p-1.5">
                  </th>
                </tr>
              </thead>
              <tbody className="tw:relative">
                {uploadedCSVStudents().map(student =>
                  <tr key={student.email} className="tw:border-transparent tw:border-b-[#edeff6]">
                    <td className="tw:align-middle tw:text-left tw:overflow-hidden tw:p-1.5">
                      <input
                        type="checkbox"
                        checked={!!student.selected}
                        onChange={(e) => {
                          uploadedCSVStudents(uploadedCSVStudents().map((s) => ({
                            ...s,
                            selected: (s.email != student.email) ? s.selected : !!e.target.checked,
                          })));
                        }}
                      />
                    </td>
                    <td className="tw:align-middle tw:text-left tw:overflow-hidden tw:p-1.5">
                      {student.name}
                    </td>
                    <td className="tw:align-middle tw:text-left tw:overflow-hidden tw:p-1.5">
                      {student.email}
                    </td>
                    <td className="tw:align-middle tw:text-left tw:overflow-hidden tw:p-1.5">
                      <div
                        className="tw:cursor-pointer tw:disabled:cursor-not-allowed"
                        onClick={(e) => {
                          uploadedCSVStudents(uploadedCSVStudents().filter((s) => s.email != student.email));
                        }}
                      >
                        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#1D1B20"/>
                        </svg>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="tw:pt-8 tw:justify-between tw:items-start tw:flex">
              <div>
                <span className="tw:text-black tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-9">
                  Do you want to add {(uploadedCSVStudents().filter(s => !!s.selected).length == 0) ? 'all' : 'selected'}
                  &nbsp;
                </span>
                <span className="tw:text-[#9a1638] tw:text-xl tw:font-bold tw:font-['Poppins'] tw:underline tw:leading-9">
                  {(uploadedCSVStudents().filter(s => !!s.selected).length > 0) ? uploadedCSVStudents().filter(s => !!s.selected).length : uploadedCSVStudents().length}
                  &nbsp;
                  student(s)
                </span>
                <span className="tw:text-black tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-9">
                  &nbsp;
                  to
                  &nbsp;
                </span>
                <span className="tw:text-[#9a1638] tw:text-xl tw:font-bold tw:font-['Poppins'] tw:underline tw:leading-9">
                  {companyPlanSelected().name}
                </span>
                <span className="tw:text-black tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-9">
                  ?
                </span>
              </div>
              <div className="tw:justify-start tw:items-center tw:gap-8 tw:flex">
                <button
                  className="tw:px-4 tw:py-2.5 tw:bg-[#14b99b] tw:rounded tw:justify-center tw:items-start tw:gap-2.5 tw:flex tw:border-0 tw:cursor-pointer tw:disabled:cursor-not-allowed"
                  disabled="disabled"
                >
                  <div className="tw:text-center tw:text-white tw:text-xl tw:font-bold tw:font-['Nunito_Sans'] tw:leading-tight">Confirm</div>
                </button>
                <div className="tw:justify-start tw:items-center tw:gap-4 tw:flex">
                  <button
                    className="tw:px-4 tw:py-2.5 tw:bg-[#939aa8] tw:rounded tw:justify-center tw:items-start tw:gap-2.5 tw:flex tw:border-0 tw:cursor-pointer tw:disabled:cursor-not-allowed"
                    disabled="disabled"
                  >
                    <div className="tw:text-center tw:text-white tw:text-xl tw:font-bold tw:font-['Nunito_Sans'] tw:leading-tight">Cancel</div>
                  </button>
                </div>
              </div>
            </div>

            <div className="tw:my-8 tw:h-px tw:bg-[#d9d9d9]" />

            <div className="tw:p-4 tw:bg-[#edeff6] tw:rounded-xl">

              <div className="tw:text-black tw:text-xl tw:font-bold tw:font-['Poppins'] tw:leading-9">{companyPlanSelected().name}</div>

              <div className="tw:flex tw:flex-row tw:w-full">
                <div>
                  <div className="tw:text-black tw:text-lg tw:font-bold tw:font-['Nunito_Sans'] tw:leading-loose">
                    {companyPlanSelected().actualUserCount} Enrolled Learners
                  </div>
                  <div className="tw:text-black tw:text-lg tw:font-bold tw:font-['Nunito_Sans'] tw:leading-loose">
                    {companyPlanSelected().totalSeats - companyPlanSelected().actualUserCount}/{companyPlanSelected().totalSeats} available licenses
                  </div>
                  <div className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-loose">
                    {DateTime.fromISO(companyPlanSelected().startsAt).startOf('day').toFormat('LLL d yyyy')}
                    &nbsp;-&nbsp;
                    {DateTime.fromISO(companyPlanSelected().expiresAt).startOf('day').toFormat('LLL d yyyy')}
                  </div>
                  {/*
                  <div className="tw:text-right">
                    <span className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">Metrics 06-2024 </span><span className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight">has </span><span className="tw:text-black tw:text-base tw:font-bold tw:font-['Nunito_Sans'] tw:underline tw:leading-tight">5</span><span className="tw:text-black tw:text-base tw:font-normal tw:font-['Nunito_Sans'] tw:leading-tight"> seat transfers avalible</span>
                  </div>
                  /**/}
                </div>
                <div className="tw:pl-32">
                  <div className="tw:text-black tw:text-base tw:font-bold tw:font-['Poppins']">Plan Courses</div>
                  <div className="tw:text-black tw:text-lg tw:font-normal tw:font-['Nunito_Sans'] tw:leading-loose">Metrics</div>
                </div>
              </div>

            </div>

            <button
              className="tw:mt-4 tw:h-10 tw:px-4 tw:py-2.5 tw:bg-[#edeff6] tw:rounded tw:justify-center tw:items-start tw:gap-2.5 tw:inline-flex tw:border-0 tw:cursor-pointer tw:disabled:cursor-not-allowed"
              disabled="disabled"
              onClick={(e) => {
                activeStep(4);
              }}
            >
              <div className="tw:text-center tw:text-[#595d64] tw:text-xl tw:font-bold tw:font-['Nunito_Sans'] tw:leading-tight">
                Next
              </div>
            </button>

          </div>
        </>)}

      </div>

    </div>

  </>);

};
