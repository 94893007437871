
'use strict';

import React from 'react';
import ReactDOM from 'react-dom';

import LoadingCenter from './LoadingCenter';

import { getFileTwine } from './ajax';

class CourseBlockTwineChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'loading', // 'loading', 'loaded'
      passages: [],
      passageCurrent: null,
    };
  }
  async componentDidMount() {
    try {
      let block = this.props.block;
      let file = await getFileTwine(block.url);
      console.log('[CourseBlockTwineChat] Fetched file from url.', { file });
      let parsed = tweeToChatflow(file);
      console.log('[CourseBlockTwineChat] Parsed twee file.', { parsed });

      this.setState({ stage: 'loaded', passages: parsed, passageCurrent: 'initial',  })

    }
    catch(e) {
      console.error(`[CourseBlockTwineChat] Error in componentDidMount().`, e);
    }
  }
  render() {
    // let block = this.props.block;

    if (this.state.stage === 'loading') {
      return (
        <div className='pi3courseTwineChat'>
          <LoadingCenter />
        </div>
      )
    }

    let currentPassageRender = '';
    if (this.state.passages.length > 0) {
      let currentPassage = this.state.passages.find(p => p.step === this.state.passageCurrent);
      if (currentPassage) {
        let answers = currentPassage.interpret.map(interpret =>
          <div>
            <span onClick={() => this.setState({ passageCurrent: interpret.goto })}>- {interpret.statements[0]}</span>
          </div>
        );
        currentPassageRender = (
          <div>
            <strong>{currentPassage.say}</strong>
            {answers}
          </div>
        );
      }
    }

    return (
      <div className='pi3courseTwineChat'>
        {currentPassageRender}
      </div>
    );
  }
}

export default CourseBlockTwineChat;

const tweeToChatflow = (source) => {

  // split source into passages
  const passages = source
    // each passage starts with a special header (::) followed by the body text of the passage
    .split(/^::/m)
    // filter out empty passages
    .filter(s => s.trim() !== '')
    // reformat and trim passages (add back the '::' prefix that was removed during the split, trim extra spaces)
    .map(s => ':: ' + s.trim())
    .map((passageSource) => {
      // take each passage and split by newlines, first line becomes headerLine
      const [headerLine, ...lines] = passageSource.split(/\r?\n/);
      // headerBits will contain three groups of information, if present:
      //   ::\s*(.*?(?:\\\s)?) captures the passage's name (with optional escaped spaces).
      //   (\[.*?\])? captures an optional passage metadata block, enclosed in square brackets [].
      //   (\{.*?\})? captures an optional passage tag or content block, enclosed in curly braces {}
      const headerBits = /^::\s*(.*?(?:\\\s)?)\s*(\[.*?\])?\s*(\{.*?\})?\s*$/.exec(
        headerLine
      );
      const [, rawName, rawTags, rawMetadata] = headerBits;

      let speaker = null;
      if (rawTags) {
        speaker = rawTags.match(/\[speaker-(.+?)\]/);
        speaker = speaker ? speaker[1] : null;
      }

      return {
        name: rawName
          // remove leading escaped spaces
          .replace(/^(\\\s)+/g, match => ' '.repeat(match.length / 2))
          // remove trailing escaped spaces
          .replace(/(\\\s)+$/g, match => ' '.repeat(match.length / 2))
          // unescape special characters
          .replace(/\\([[\]{}])/g, '$1')
          // unescape double backlashes
          .replace(/\\\\/g, '\\') // unescapeForTweeHeader
          // replace spaces with underscores
          .replace(/[ ]/g, '_') 
          // remove non-alphanumeric characters (except underscores and hyphens)
          .replace(/[^A-Za-z0-9\_\-]+/g, '')
          // convert to lowercase
          .toLowerCase()
        ,
        text: lines
          //  ^\\: searches for a backslash-escaped colon (\\:) at the beginning of any line (^) in a multiline string (indicated by the gm)
          // replaces every escaped colon (\\:) with a regular colon (:)
          .map(v => v.replace(/^\\:/gm, ':')) // unescapeForTweeText
          .join('\n')
          .trim()
        ,
        rawTags,
        rawMetadata,
        speaker,
      };
    })
  ;

  console.log('[tweeToChatflow] Extracted passages.', passages);

  // story metadata, such as format, initial step name, tag colors
  const storydata = JSON.parse(passages.filter(p => p.name == 'storydata')?.[0]?.text ?? '{}');

  console.log('[tweeToChatflow] Extracted storydata.', storydata);

  // step name generator?
  const initialStepName = (name) => {
    const startName = storydata.start
      // replace spaces with underscores
      .replace(/[ ]/g, '_')
      // remove special characters (leave alfanumeric, underscores & hyphens)
      .replace(/[^A-Za-z0-9\_\-]+/g, '')
      .toLowerCase()
    ;
    const stepName = name
      // replace spaces with underscores
      .replace(/[ ]/g, '_')
      // remove special characters (leave alfanumeric, underscores & hyphens)
      .replace(/[^A-Za-z0-9\_\-]+/g, '')
      .toLowerCase()
    ;
    if (startName == stepName)
      return 'initial';
    return stepName;
  };


  const chatflow = passages
    // exclude passages with name equal to 'storydata', 'storytitle', or 'storystylesheet' (exclude metadata passages)
    .filter(p => !['storydata', 'storytitle', 'storystylesheet'].includes(p.name))
    .map((passage) => {
      // \[\[ matches the opening double square brackets [[.
      // (.*?) is a capturing group that matches any characters inside the brackets (non-greedily).
      // \]\] matches the closing double square brackets ]].
      const choices = (passage.text.match(/\[\[(.*?)\]\]/g) ?? [])
        .map(p => p.replace(/\[\[(.*?)\]\]/, (m0, m1) => m1))
      ;
      return {
        step: initialStepName(passage.name),
        // \[\[ matches the opening double square brackets [[.
        // (.*?): This captures any characters inside the brackets (non-greedily).
        // \]\] matches the closing double square brackets ]].
        say: passage.text.replace(/\[\[(.*?)\]\]/g, '').trim(),
        // \-\>: This matches the literal characters ->. The backslashes (\) are used to escape the hyphen (-), as it has special meaning in regex.
        // .*?: This matches any characters after -> (non-greedily).
        // $: This asserts the end of the string, ensuring the match occurs only at the end.
        // The matched part is replaced with an empty string '', which effectively removes the -> and anything after it.
        choices: choices.map(c => c.replace(/\-\>.*?$/, '')),
        interpret: choices.map(c => ({
          as: 'meaning',
          statements: [c.replace(/\-\>.*?$/, '')],

          // Input Choice: "Go to the forest -> forestScene".
          // Processed Destination:
          //   After removing text before ->: "forestScene".
          //   After replacing spaces: "forestScene" (no spaces to replace).
          //   After removing special characters: "forestscene" (remains unchanged).
          //   After converting to lowercase: "forestscene".
          // Function Call: initialStepName("forestscene").
          // Resulting goto: The result of initialStepName("forestscene") is assigned to goto.
          goto: initialStepName(
            c
              .replace(/^.*?\-\>/, '')
              .replace(/[ ]/g, '_')
              .replace(/[^A-Za-z0-9\_\-]+/g, '')
              .toLowerCase()
          ),
        })),
        speaker: passage.speaker,
      };
    })
  ;

  return chatflow;

};