'use strict';

const Company = {
  LicenseType: {
    // 'company' belongs to an individual grouping rather than actual company
    Individual: 0,
    // standard plan has a quota on available seats
    Standard: 100,
    // premium plan has a quota on seats + reporting
    Premium: 200,
    // large/enterprise plan has unlimited seats
    Enterprise: 400,
  }
};

const CompanyPlan = {
  Type: {
    // access to legacy course, until CompanyPlan.expiresAt w/ limited seat count
    LegacyStandard: 1,
    // permanent access to legacy course w/ limited seat count
    LegacyPremium: 2,
    // access to legacy course until CompanyPlan.expiresAt w/ unlimited seat count
    LegacyEnterprise: 3,

    // current 'standard plan':  access to main course, until CompanyPlan.expiresAt w/ limited seat count
    TeamsStandard: 4,
    // current 'premium plan': access to main bundle, until CompanyPlan.expiresAt w/ limited seat count
    TeamsPremium: 5, 
    // current 'enterprise plan'
    TeamsEnterprise: 6, // access to main bundle until CompanyPlan.expiresAt w/ limited seat count
  },
};

const CompanyPlanUser = {
  Role: {
    Student: null,
    Manager: 1,
  }
};

const User = {
  Role: {
    Student: 1,
    Manager: 2, // deprecated?
    Admin: 3,
    // Author: 4,
  },
  isIndividual: user => {
    if (!user) {
      return false;
    }
    if (user.companyId === null) {
      return true;
    }
    if (user.company && user.company.licenseType === Company.LicenseType.Individual) {
      return true;
    }
    return false;
  }
};

const UserActivity = { 
  Type: {
    LessonComplete: 1,
    LessonView: 2,
    QuizComplete: 3,
    Login: 4,
  }
};

const ToolkitDownloadable = {
  Type: {
    Resource: 1,
    Worksheet: 2,
  },
  FileType: {
    Resource: 1,
    Worksheet: 2,
    WorksheetExample: 3,
  },
};


const CourseSection = {
  PassCriteria: {
    // default, section is completed if quiz was solved above pass criteria
    SectionQuizPassed: null,
    // mark section as completed if quiz was taken (regardless of score), used for 'product management foundations' week/module 10
    SectionQuizTaken: 1,
    // section is completed if any lecture inside section is taken - used for 'market research' week/module 10
    SectionLectureCompleted: 2,
  }
};

const CourseLesson = {
  Status: {
    Unread: 1,
    Reading: 2,
    Read: 3,
  },
  Type: {
    Text: 1,
    Video: 2,
    Quiz: 3,
    Chatflow: 4,
    QuizPlus: 5, // quiz with rich text, video etc.
  }
};

const CourseQuiz = {
  QuestionType: {
    SingleCorrect: 1, // only one answer is correct
    MultipleCorrect: 2, // multiple answers selected together are correct
  },
};

const CourseQuizPlus = {
  QuestionType: {
    SingleCorrect: 1, // only one answer is correct
    MultipleCorrect: 2, // multiple answers selected together are correct
  },
};

const CourseBlock = {
  Type: {
    Text: 1,
    Image: 2,
    Video: 3,
    YouTube: 4,
    Document: 5,
    CustomCode: 6,
    TwineChat: 7,
  }
};


// new-purchase-flow
const IndividualProduct = {
  Type: {
    MainCourse: 1,
    Dessert: 2,
  },
};
const CompanyProduct = {
  Type: {
    CompanySeat: 1,
    CoachingPackage: 2,
  }
};
const CompanyOnboarding = {
  PaymentMethod: {
    Stripe: 1,
    WireTransfer: 2,
  },
  // move this from Company.LicenseType, there's a lucky mismatch now
  LicenseType: {
    // all course 'bundle' access, no reporting (deprecated 2021-11)
    Standard: 100,
    // all course 'bundle', with reporting
    Premium: 200,
    // PM Foundations course only, with reporting
    PMFoundations: 300,
  },
};

module.exports = {
  User,
  UserActivity,
  Company,
  CompanyPlan,
  CompanyPlanUser,
  ToolkitDownloadable,

  CourseSection,
  CourseLesson,
  CourseBlock,
  CourseQuiz,
  CourseQuizPlus,

  IndividualProduct,
  CompanyProduct,
  CompanyOnboarding,
};
